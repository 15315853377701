export const COURSE_TEXT_TYPE = 'course-contents.page';
export const COURSE_VIDEO_TYPE = 'course-contents.video';
export const COURSE_IMAGE_TYPE = 'course-contents.image';
export const COURSE_INPUT_TYPE = 'course-contents.input';
export const COURSE_QUIZ_TYPE = 'course-contents.q-a';

export interface TextContent {
  __component: typeof COURSE_TEXT_TYPE;
  id: number;
  text: string;
}

export interface VideoContent {
  __component: typeof COURSE_VIDEO_TYPE;
  id: number;
  name: string;
  video: {
    url: string;
  };
  video_preview: {
    url: string;
  };
}

export interface InputContent {
  __component: typeof COURSE_INPUT_TYPE;
  id: number;
  description: string;
  paragraph: boolean;
}

export interface ImageData {
  name: string;
  width: Number;
  height: Number;
  url: string;
  alternativeText?: string;
  caption?: string;
  ext?: string;
  mime?: string;
}

export interface Image extends ImageData {
  thumbnail?: ImageData;
  small?: ImageData;
}

export interface ImageContent {
  __component: typeof COURSE_IMAGE_TYPE;
  id: number;
  image: Image;
}

export type ValidQuizAnswer = 1 | 2 | 3 | 4 | 5;

export interface QuizChoice {
  id: string;
  answer: string;
  is_answer: boolean;
}

export interface QuizContent {
  __component: typeof COURSE_QUIZ_TYPE;
  id: number;
  quiz_header: string;
  quiz_choice: QuizChoice[];
  correct_answer_page: {
    id: string;
    title: string;
    description: string;
    header: ImageData;
  };
  wrong_answer_page: {
    id: string;
    title: string;
    description: string;
    header: ImageData;
  };
}

export type CourseContent =
  | TextContent
  | ImageContent
  | InputContent
  | VideoContent
  | QuizContent;
