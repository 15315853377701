import { useMemo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Container,
  Box,
  Typography,
  Paper,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Skeleton from '../../components/Skeleton';
import { RootState } from '../../redux/reducers';
import loadQuestionnaires from '../../redux/thunks/loadQuestionnaires';
import { postQuestionnaire } from '../../services/questionnaire';
import style from '../style';
import shuffle from '../../utils/shuffle';

const useStyles = makeStyles(style);

const Questionnaire = () => {
  const [value, setValue] = useState<{ [name: string]: string }>({});
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { data: questionnaires, loading } = useSelector(
    (state: RootState) => state.questionnaires,
  );

  const onSubmitQuestionnaire = async (e: any) => {
    e.preventDefault();
    setSubmitting(true);

    try {
      const request: { [name: string]: string }[] = [];

      Object.keys(value).forEach((key) => {
        request.push({
          question: key,
          answer: value[key],
        });
      });

      await postQuestionnaire(questionnaires?.title || '', request);

      setSubmitted(true);
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  const onRadioChange = (key: string, e: any) => {
    setValue((prev) => ({
      ...prev,
      [key]: e.target.value,
    }));
  };

  const randomQuestions = useMemo(() => {
    const shuffled = shuffle(questionnaires?.questions || []);
    return shuffled.splice(0, questionnaires?.questionsCount || 5);
  }, [questionnaires]);

  const canSubmit = useMemo(() => {
    let result = true;
    questionnaires?.questions.forEach((question) => {
      if (question.Required && !value[question.Title]) {
        result = false;
      }
    });
    return result;
  }, [value, questionnaires]);

  useEffect(() => {
    if (!questionnaires) {
      dispatch(loadQuestionnaires());
    }
  }, [dispatch, questionnaires]);

  return (
    <Container fixed>
      <Paper variant="outlined" className={classes.panel}>
        {submitted ? (
          <>
            <Alert severity="success">
              You have successfully commpleted your questionnaire.
            </Alert>
            <Box marginTop={3}>
              <Button component={Link} to="/" variant="contained" color="primary">
                Home
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Typography
              className={classes.title}
              variant="h5"
              align="center"
              gutterBottom
            >
              {questionnaires?.title}
            </Typography>
            {loading ? (
              <Skeleton />
            ) : (
              questionnaires?.questions && (
                <Box>
                  <form onSubmit={onSubmitQuestionnaire}>
                    {questionnaires?.questions.map((questionnaire, index) => (
                      <Box key={questionnaire._id} marginTop={2}>
                        <Typography
                          className={classes.subtitle}
                          variant="h5"
                          align="left"
                          gutterBottom
                        >
                          {questionnaire.Title}
                          {questionnaire.Required ? (
                            <span className={classes.required}>
                              {' '}
                              - required
                            </span>
                          ) : (
                            ''
                          )}
                        </Typography>
                        <Box>
                          <FormControl required={questionnaire.Required}>
                            <RadioGroup
                              name={`questionnaire_${questionnaire._id}`}
                              onChange={(e) => onRadioChange(questionnaire.Title, e)}
                            >
                              {questionnaire.Choices.map((choice) => (
                                <FormControlLabel
                                  key={choice._id}
                                  value={choice.Text}
                                  control={<Radio />}
                                  label={choice.Text}
                                />
                              ))}
                            </RadioGroup>
                          </FormControl>
                        </Box>
                      </Box>
                    ))}
                    {canSubmit && (
                      <Button
                        className={classes.navButton}
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={submitting}
                      >
                        Submit
                      </Button>
                    )}
                  </form>
                </Box>
              )
            )}
          </>
        )}
      </Paper>
    </Container>
  );
};

export default Questionnaire;
