import axios from 'axios';
import courseApiPath from './courseApiPath';
import { CourseInterface, CourseIdentity } from '../types';

export const loadCoursesList = async (): Promise<CourseIdentity[]> => {
  const response = await axios.get(courseApiPath('/courses'));
  const responseData = await response.data;

  return responseData;
};

export const loadCourse = async (courseSlug: string): Promise<CourseInterface | null> => {
  const response = await axios.get(courseApiPath(`/courses/${courseSlug}`));
  if (response.status === 404) {
    return null;
  }
  const responseData = await response.data;

  return responseData;
};
