/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
import { useMemo } from 'react';
import courseApiPath from '../../services/courseApiPath';
import {
  COURSE_IMAGE_TYPE,
  COURSE_TEXT_TYPE,
  COURSE_VIDEO_TYPE,
  COURSE_INPUT_TYPE,
  PageContent,
  QuizContent,
} from '../../types';
import CourseImage from '../CourseImage';
import CourseQuiz from '../CourseQuiz';
import CourseText from '../CourseText';
import CourseVideo from '../CourseVideo';
import CourseAnswer from '../CourseAnswer';
import CourseInput from '../CourseInput';
import styles from './styles.module.scss';

interface CoursePageProps {
  page: PageContent;
}

const CoursePage = ({ page }: CoursePageProps) => {
  const { contents, answerPage } = page;

  const qaContent = contents.find(
    (content) => content.__component === 'course-contents.q-a',
  ) as QuizContent;

  const pageContents = useMemo(() => {
    if (answerPage) {
      return contents.filter((item) => item.__component === COURSE_INPUT_TYPE);
    }
    if (qaContent) {
      return contents.filter((item) => item.__component !== COURSE_INPUT_TYPE);
    }
    return contents;
  }, [contents, answerPage, qaContent]);

  const contentNodes = pageContents.map((content, i) => {
    switch (content.__component) {
      case COURSE_TEXT_TYPE:
        return (
          <CourseText
            content={content}
            key={`${content.__component}-${content.id}`}
          />
        );

      case COURSE_VIDEO_TYPE:
        return (
          <CourseVideo
            content={content}
            key={`${content.__component}-${content.id}`}
          />
        );

      case COURSE_IMAGE_TYPE:
        return (
          <CourseImage
            content={content}
            key={`${content.__component}-${content.id}`}
          />
        );

      case COURSE_INPUT_TYPE:
        return (
          <CourseInput
            content={content}
            key={`${content.__component}-${content.id}`}
          />
        );

      default:
        return null;
    }
  });

  if (page.answerPage && qaContent) {
    return (
      <div className={styles.coursePage}>
        <CourseAnswer quiz={qaContent} />
        {contentNodes}
      </div>
    );
  }

  return (
    <div className={styles.coursePage}>
      <div className={styles.header}>
        {page.header && (
          <img
            key={page.header.url}
            src={courseApiPath(page.header.url)}
            alt="Header"
          />
        )}
      </div>
      {contentNodes}
      {qaContent && <CourseQuiz quiz={qaContent} />}
    </div>
  );
};

CoursePage.displayName = 'CoursePage';

export default CoursePage;
