import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from '@material-ui/core';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import moment from 'moment';
import Skeleton from '../../components/Skeleton';
import { Appointment } from '../../types';

const useStyles = makeStyles(() => createStyles({
  subTitle: {
    fontSize: 18,
    fontWeight: 600,
  },
}));

interface AppointmentsProps {
  loading: boolean;
  appointments?: Appointment[];
}

const Appointments: React.FC<AppointmentsProps> = ({
  loading,
  appointments = [],
}) => {
  const classes = useStyles();

  if (loading) {
    return (
      <Box marginTop={3}>
        <Skeleton />
      </Box>
    );
  }

  return (
    <Box marginTop={3}>
      {appointments.length ? (
        <>
          <Typography className={classes.subTitle} variant="h5" align="left" gutterBottom>
            Upcoming Appointments
          </Typography>
          <List dense>
            {appointments.map((item) => (
              <ListItem key={item.id}>
                <ListItemAvatar>
                  <Avatar>
                    <CalendarIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`${moment(item.at).format('LLLL')} with ${item.employee?.name}`}
                />
              </ListItem>
            ))}
          </List>
        </>
      ) : (
        <Typography className={classes.subTitle} variant="h5" align="left" gutterBottom>
          No upcoming appointments found.
        </Typography>
      )}
    </Box>
  );
};

Appointments.displayName = 'Appointments';

export default Appointments;
