import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory, Redirect } from 'react-router-dom';
import qs from 'query-string';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import loadOpenings from '../../redux/thunks/loadOpenings';
import { openingsReset } from '../../redux/actions/openings';
import { RootState } from '../../redux/reducers';
import { updateProfile } from '../../services/appointment';
import SearchOpening from './SearchOpening';
import SelectOpening from './SelectOpening';
import SelectConfirm from './SelectConfirm';
import ConfirmAddress from './ConfirmAddress';
import style from '../style';
import loadPatient from '../../redux/thunks/loadPatient';

const useStyles = makeStyles(style);

const Schedule: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { insurance_id: insuranceId } = qs.parse(location.search);
  const openings = useSelector((state: RootState) => state.openings);
  const patient = useSelector((state: RootState) => state.patient);

  const [selected, setSelected] = useState<string | null>(null);
  const [addressUpdating, setAddressUpdating] = useState<boolean>(false);

  const onSearchOpening = (startDate: Date, endDate: Date) => {
    dispatch(
      loadOpenings(
        format(startDate || new Date(), 'yyyy-MM-dd'),
        format(endDate || new Date(), 'yyyy-MM-dd'),
        String(insuranceId),
      ),
    );
  };

  const onReset = () => {
    dispatch(openingsReset());
  };

  const onConfirm = () => {
    try {
      if (selected) {
        history.push(`/payment?insurance_id=${insuranceId}&time=${selected}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onUpdateAddress = async (request: any) => {
    setAddressUpdating(true);
    try {
      await updateProfile(request);
      dispatch(loadPatient());
    } catch (error) {
      console.log(error);
    } finally {
      setAddressUpdating(false);
    }
  };

  const hasAddress = useMemo(() => {
    if (patient.data) {
      return (
        Boolean(patient.data.address)
        && Boolean(patient.data.city)
        && Boolean(patient.data.state)
        && Boolean(patient.data.zip)
        && Boolean(patient.data.phone)
      );
    }
    return false;
  }, [patient]);

  if (!insuranceId) {
    return <Redirect to="/" />;
  }

  if (!hasAddress) {
    return (
      <Container className={classes.root} fixed>
        <ConfirmAddress loading={addressUpdating} onUpdate={onUpdateAddress} />
      </Container>
    );
  }

  return (
    <Container className={classes.root} fixed>
      {openings.data.length === 0 ? (
        <>
          <SearchOpening
            loading={openings.loading}
            error={openings.error || ''}
            onSearch={onSearchOpening}
          />
        </>
      ) : (
        <>
          {!selected && (
            <SelectOpening
              openings={openings.data}
              onSelect={(val: string) => setSelected(val)}
              onReset={onReset}
            />
          )}
          {selected && (
            <SelectConfirm
              selected={selected}
              onConfirm={onConfirm}
              onReset={() => setSelected(null)}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default Schedule;
