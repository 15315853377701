import {
  CourseInterface,
  CourseIdentity,
  CourseInteractionsKeys,
  CourseInteractionTypes,
} from '../../types';

// Action constants
export const STORE_COURSES_LIST = 'STORE_COURSES_LIST';
export const STORE_COURSE = 'STORE_COURSE';
export const CLEAR_COURSE = 'CLEAR_COURSE';
export const INTERACT_WITH_COURSE = 'INTERACT_WITH_COURSE';

// Types
export interface StoreCoursesList {
  type: typeof STORE_COURSES_LIST;
  courses: CourseIdentity[];
}

export interface StoreCourse {
  type: typeof STORE_COURSE;
  course: CourseInterface;
}

export interface ClearCourse {
  type: typeof CLEAR_COURSE;
}

export interface InteractWithCourse {
  type: typeof INTERACT_WITH_COURSE;
  interactionType: CourseInteractionsKeys;
  key: string;
  value: CourseInteractionTypes | null;
}

// Action builders
export const storeCoursesList = (
  courses: CourseIdentity[],
): StoreCoursesList => ({ type: STORE_COURSES_LIST, courses });

export const storeCourse = (course: CourseInterface): StoreCourse => ({
  type: STORE_COURSE,
  course,
});

export const clearCourse = (): ClearCourse => ({ type: CLEAR_COURSE });

export const interactWithCourse = (
  interactionType: CourseInteractionsKeys,
  key: string,
  value: CourseInteractionTypes | null,
) => ({
  type: INTERACT_WITH_COURSE,
  interactionType,
  key,
  value,
});
