import moment from 'moment';
import {
  ListItem,
  ListItemText,
  ListItemAvatar,
  Tooltip,
  Avatar,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  red,
  // pink,
  // purple,
  // deepPurple,
  // deepOrange,
  // indigo,
  // blue,
  // lightBlue,
  // cyan,
  // teal,
  // green,
} from '@material-ui/core/colors';
import { Post } from '../../types';

interface FeedCommentItemProps {
  comment: Post;
}

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    backgroundColor: red[500],
  },
  media: {
    objectPosition: 'top',
  },
  text: {
    fontSize: theme.typography.body1.fontSize,
  },
  subText: {
    fontSize: theme.typography.caption.fontSize,
  }
}));

const FeedCommentItem = ({ comment }: FeedCommentItemProps) => {
  const classes = useStyles();

  return (
    <ListItem disableGutters>
      <ListItemAvatar>
        <Tooltip
          title={`${comment.author.first_name} ${comment.author.last_name}`}
        >
          <Avatar className={classes.avatar} src={comment?.author?.profilePic?.url}>
            {comment.author.first_name?.charAt(0)}
          </Avatar>
        </Tooltip>
      </ListItemAvatar>
      <ListItemText
        classes={{
          primary: classes.text,
          secondary: classes.subText,
        }}
        primary={comment.content}
        secondary={moment(comment.published_at).format('MMMM Do YYYY')}
      />
    </ListItem>
  );
};

export default FeedCommentItem;
