import React from 'react';
import {
  Box, Typography, Paper, Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBack } from '@material-ui/icons';
import moment from 'moment';
import style from '../style';

const useStyles = makeStyles(style);

interface ConfirmOpeningProps {
  selected: string;
  onConfirm: () => void;
  onReset: () => void;
}

const ConfirmOpening: React.FC<ConfirmOpeningProps> = ({
  selected,
  onConfirm,
  onReset,
}) => {
  const classes = useStyles();

  return (
    <Paper variant="outlined" className={classes.panel}>
      <Typography
        className={classes.title}
        variant="h1"
        align="center"
        gutterBottom
      >
        Confirm appointment
      </Typography>
      <Typography
        className={classes.subtitle}
        variant="h5"
        align="center"
        gutterBottom
      >
        You have selected the following appointment. Please review the
        information below, and click "confirm" to book the appointment.
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        marginTop={3}
        marginBottom={3}
      >
        <Box className={classes.openings}>
          <Box className={classes.openingSlot}>
            <Box className={classes.openingItem}>
              {`${moment(selected).format('M/D/YYYY')} at ${moment(
                selected,
              ).format('h:mm a')}`}
            </Box>
            <Button
              className={classes.confirmButton}
              variant="contained"
              color="primary"
              onClick={onConfirm}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Button
          variant="contained"
          startIcon={<ArrowBack />}
          onClick={onReset}
        >
          Back to results
        </Button>
      </Box>
    </Paper>
  );
};

export default ConfirmOpening;
