import { AppThunk } from '../../types';
import {
  authRequest, authSuccess, authError,
} from '../actions/user';
import * as authApi from '../../services/auth';

const loadPatient = (): AppThunk => async (dispatch) => {
  dispatch(authRequest());
  try {
    const user = await authApi.loadPatient();
    dispatch(authSuccess(user));

    return user;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    dispatch(authError());
  }
};

export default loadPatient;
