import { Service } from '../../types';

// Action constants
export const SERVICES_REQUEST = 'SERVICES_REQUEST';
export const SERVICES_SUCCESS = 'SERVICES_SUCCESS';
export const SERVICES_ERROR = 'SERVICES_ERROR';

// Action builders
export const servicesRequest = () => ({
  type: SERVICES_REQUEST,
});

export const servicesSuccess = (services: Service[]) => ({
  type: SERVICES_SUCCESS,
  services,
});

export const servicesError = (error: any) => ({
  type: SERVICES_ERROR,
  error,
});
