import { createStyles, Theme } from '@material-ui/core/styles';

const style = (theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  title: {
    // marginBottom: 24,
    fontSize: 36,
    fontWeight: 400,
    color: '#de0c70',
  },
  subtitle: {
    fontSize: 18,
    fontWeight: 400,
  },
  pickerLayout: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  textField: {
    margin: '0 8px',
  },
  button: {
    margin: '0 8px',
  },
  loadingBtn: {
    marginRight: 8,
  },
  openings: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxHeight: 500,
    width: 360,
    overflowY: 'auto',
  },
  openingItem: {
    padding: '24px 0',
    textAlign: 'center',
    backgroundColor: '#e0e0e0',
  },
  openingButton: {
    width: '100%',
  },
  confirmButton: {
    width: '100%',
  },
  openingSlot: {
    width: '100%',
    margin: '8px 0',
  },
  panel: {
    width: 650,
    maxWidth: '100%',
    margin: '12px auto',
    padding: '30px 50px 50px',
    borderRadius: 3,
    boxSizing: 'border-box',

    [theme.breakpoints.down('sm')]: {
      padding: '50px 10px',
    },
  },
  formItem: {
    width: 300,
  },
  applyButton: {
    marginLeft: 8,
  },
  stripeContainer: {
    width: 380,
    padding: '18.5px 14px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 4,
  },
  instruction: {
    '& li': {
      marginBottom: 12,
    },
  },
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    marginLeft: theme.spacing(2),
  },
  required: {
    fontSize: 12,
    fontStyle: 'italic',
  },
  navButton: {
    padding: '12px 24px',
    marginRight: 12,
    marginTop: 24,
    borderRadius: 5,

    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  },
  stateSelect: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  redirectButton: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    lineHeight: 1.2,
  },
  input: {
    width: 0,
    visibility: 'hidden',
  },
});

export default style;
