import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Box,
  Typography,
  Paper,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { add } from 'date-fns';
import { Alert } from '@material-ui/lab';
import style from '../style';
import { openingsReset } from '../../redux/actions/openings';

const useStyles = makeStyles(style);

interface SearchOpeningProps {
  loading?: boolean;
  error?: string;
  onSearch: (startDate: Date, endDate: Date) => void;
}

const SearchOpening: React.FC<SearchOpeningProps> = ({
  loading = false,
  error,
  onSearch,
}: SearchOpeningProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(
    add(new Date(), { days: 2 }),
  );

  return (
    <Paper variant="outlined" className={classes.panel}>
      <Typography
        className={classes.title}
        variant="h1"
        align="center"
        gutterBottom
      >
        Book an appointment
      </Typography>
      <Typography
        className={classes.subtitle}
        variant="h5"
        align="center"
        gutterBottom
      >
        Search for appointments between:
      </Typography>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Box
          className={classes.pickerLayout}
          display="flex"
          alignItems="center"
          justifyContent="center"
          marginTop={4}
        >
          <DatePicker
            className={classes.textField}
            autoOk
            clearable
            variant="inline"
            inputVariant="outlined"
            label="From"
            value={startDate}
            placeholder="10/10/2018"
            onChange={(date) => setStartDate(date)}
            format="MM/dd/yyyy"
          />
          <Typography
            className={classes.subtitle}
            variant="h5"
            align="center"
            gutterBottom
          >
            And
          </Typography>
          <DatePicker
            className={classes.textField}
            autoOk
            clearable
            variant="inline"
            inputVariant="outlined"
            label="To"
            value={endDate}
            placeholder="10/10/2018"
            onChange={(date) => setEndDate(date)}
            minDate={startDate}
            format="MM/dd/yyyy"
          />
        </Box>
      </MuiPickersUtilsProvider>
      {error && (
        <Box marginTop={3}>
          <Alert severity="warning">
            {`We're sorry but there are no available appointment times with
              a licensed provider in your state. We will email you when one
              opens up. Thank you for your interest and we will follow up with you shortly.`}
          </Alert>
        </Box>
      )}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        marginTop={4}
      >
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          disabled={!startDate || !endDate || loading}
          onClick={() => onSearch(startDate || new Date(), endDate || new Date())}
        >
          {loading && (
            <CircularProgress className={classes.loadingBtn} size={25} />
          )}
          Search
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          component={Link}
          to="/"
          onClick={() => dispatch(openingsReset())}
        >
          Cancel
        </Button>
      </Box>
    </Paper>
  );
};

export default SearchOpening;
