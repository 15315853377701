import { StrictMode } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import ReactDOM from 'react-dom';
import App from './App';
import store from './redux/store';
import './utils/axios';

import './index.scss';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#de0c70',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        padding: '0 30px',
        height: 52,
        fontSize: 16,
        lineHeight: 52,
        textTransform: 'none',
        border: 'none',
        boxShadow: 'none',
        borderRadius: 0,
        overflow: 'hidden',
      },
      contained: {
        boxShadow: 'none',
      },
      containedSizeSmall: {
        height: 32,
      },
    },
  },
});

ReactDOM.render(
  <StrictMode>
    <Router>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Provider>
    </Router>
  </StrictMode>,
  document.getElementById('root'),
);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
