import gfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';

interface MarkdownTextProps {
  children: string;
  className?: string;
}

const MarkdownText = ({ children, className }: MarkdownTextProps) => (
  <ReactMarkdown className={className} plugins={[gfm]}>
    {children}
  </ReactMarkdown>
);

export default MarkdownText;
