import { AppThunk } from '../../types';
import {
  openingsRequest,
  openingsSuccess,
  openingsError,
} from '../actions/openings';
import { loadOpenings as getOpenings } from '../../services/appointment';

const loadOpenings = (
  startDate: string, endDate: string, insuranceId: string,
): AppThunk => async (dispatch) => {
  dispatch(openingsRequest());

  try {
    const openings = await getOpenings(startDate, endDate, insuranceId);

    if (openings.openings && openings.openings.length > 0) {
      dispatch(openingsSuccess(openings.openings || []));
    } else {
      dispatch(openingsError('No results found'));
    }
  } catch (error) {
    dispatch(openingsError(error));
  }
};

export default loadOpenings;
