import { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from './redux/reducers';
import Login from './views/Login';
import ForgotPassword from './views/ForgotPassword';
import ResetPassword from './views/ResetPassword';
import Contents from './views/Contents';
import CourseView from './views/CourseView';
import CoursesList from './views/CoursesList';
import Home from './views/Home';
import UserInfo from './components/UserInfo';
import PrivateRoute from './components/PrivateRoute';
import ProfileView from './views/ProfileView';
import Schedule from './views/Schedule';
import Instruction from './views/Instruction';
import Questionnaire from './views/Questionnaire';
import Payment from './views/Payment';
import Membership from './views/Membership';
import Confirmed from './views/Confirmed';
import loadPatient from './redux/thunks/loadPatient';

import styles from './App.module.scss';
import Community from './views/Community';

const App = () => {
  const dispatch = useDispatch();
  const authJWT = useSelector((state: RootState) => state.authJWT);
  const { data: user } = useSelector((state: RootState) => state.patient);

  useEffect(() => {
    if (!user && authJWT) {
      dispatch(loadPatient());
    }
  }, [user, authJWT, dispatch]);

  return (
    <div className={styles.app}>
      <UserInfo />
      <Switch>
        <PrivateRoute path="/profile">
          <ProfileView />
        </PrivateRoute>
        <PrivateRoute path="/contents">
          <Contents />
        </PrivateRoute>
        <PrivateRoute path="/courses">
          <CoursesList />
        </PrivateRoute>
        <PrivateRoute path="/course/:courseSlug">
          <CourseView />
        </PrivateRoute>
        <PrivateRoute path="/schedule">
          <Schedule />
        </PrivateRoute>
        <PrivateRoute path="/instruction">
          <Instruction />
        </PrivateRoute>
        <PrivateRoute path="/questionnaire">
          <Questionnaire />
        </PrivateRoute>
        <PrivateRoute path="/payment">
          <Payment />
        </PrivateRoute>
        <PrivateRoute path="/membership">
          <Membership />
        </PrivateRoute>
        <PrivateRoute path="/confirmed">
          <Confirmed />
        </PrivateRoute>
        <PrivateRoute path="/community">
          <Community />
        </PrivateRoute>
        <PrivateRoute exact path="/">
          <Home />
        </PrivateRoute>
        <Route path="/login" component={Login} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/reset-password" component={ResetPassword} />
        <Redirect to="/" />
      </Switch>
    </div>
  );
};

App.displayName = 'App';

export default App;
