import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/reducers';

interface PrivateRouteProps extends RouteProps {
  isAdministrator?: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  children,
  isAdministrator = false,
  ...rest
}) => {
  let isAllowed = false;
  const authJWT = useSelector((state: RootState) => state.authJWT);
  const { data: user } = useSelector((state: RootState) => state.patient);

  if (isAdministrator) {
    isAllowed = Boolean(authJWT) && Boolean(user) && user?.role?.type === 'administrator';
  } else {
    isAllowed = Boolean(authJWT);
  }

  return (
    <Route
      {...rest}
      render={({ location }) => (isAllowed ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location },
          }}
        />
      ))}
    />
  );
};

export default PrivateRoute;
