import React from 'react';
import {
  Box, Typography, Paper, Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBack } from '@material-ui/icons';
import moment from 'moment';
import style from '../style';

const useStyles = makeStyles(style);

interface SelectOpeningProps {
  openings?: string[];
  onSelect: (date: string) => void;
  onReset: () => void;
}

const SelectOpening: React.FC<SelectOpeningProps> = ({ openings = [], onSelect, onReset }) => {
  const classes = useStyles();

  return (
    <Paper variant="outlined" className={classes.panel}>
      <Typography
        className={classes.title}
        variant="h1"
        align="center"
        gutterBottom
      >
        Appointments
      </Typography>
      <Typography
        className={classes.subtitle}
        variant="h5"
        align="center"
        gutterBottom
      >
        Book an appointment below:
      </Typography>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" marginTop={3} marginBottom={3}>
        <Box className={classes.openings}>
          {openings.map((opening) => (
            <Box key={opening} marginBottom={2} className={classes.openingSlot}>
              <Box className={classes.openingItem}>
                {`${moment(opening).format('M/D/YYYY')} at ${moment(opening).format('h:mm a')}`}
              </Box>
              <Button
                className={classes.openingButton}
                variant="contained"
                color="primary"
                onClick={() => onSelect(opening)}
              >
                Select
              </Button>
            </Box>
          ))}
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Button
          variant="contained"
          startIcon={<ArrowBack />}
          onClick={onReset}
        >
          Change Dates
        </Button>
      </Box>
    </Paper>
  );
};

export default SelectOpening;
