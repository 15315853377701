import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import courseApiPath from '../../services/courseApiPath';
import { QuizContent } from '../../types';
import MarkdownText from '../MarkdownText';
import styles from './styles.module.scss';

interface CourseAnswerProps {
  quiz: QuizContent;
}

const CourseAnswer = ({ quiz }: CourseAnswerProps) => {
  const interactions = useSelector((state: RootState) => state.interactions);

  const answer = useMemo(() => {
    if (quiz.quiz_header && interactions.quiz[quiz.quiz_header]) {
      const selection = interactions.quiz[quiz.quiz_header]?.selectedAnswer || null;
      const questions = interactions.quiz[quiz.quiz_header]?.question || [];
      const selected = questions.find((item) => item.id === selection);
      if (selected && selected.is_answer) {
        return quiz.correct_answer_page;
      }
      return quiz.wrong_answer_page;
    }
    return null;
  }, [quiz, interactions]);

  return (
    <>
      <header className={styles.header}>
        {answer && answer.header && (
          <img src={courseApiPath(answer.header.url)} alt={answer.title} />
        )}
        <div className={styles.title}>{answer?.title}</div>
      </header>
      <MarkdownText className={styles.text}>
        {answer?.description || ''}
      </MarkdownText>
    </>
  );
};

CourseAnswer.displayName = 'CourseAnswer';

export default CourseAnswer;
