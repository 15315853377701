import axios from 'axios';
import { strapiPath } from './authApiPath';
import { Post, Comment } from '../types';

export const loadPosts = async (start: number = 0): Promise<Post[]> => {
  const response = await axios.get(
    strapiPath(`/posts?_sort=published_at:DESC&_start=${start}&_limit=10`),
  );
  const responseData = await response.data;

  return responseData;
};

export const createPost = async (newPost: any): Promise<Post> => {
  const response = await axios.post(strapiPath('/posts'), newPost);
  return response.data;
};

export const likePost = async (postId: string): Promise<Post> => {
  const response = await axios.post(strapiPath(`/posts/${postId}/follow`));
  return response.data;
};

export const commentPost = async (
  postId: string,
  content: string,
): Promise<Post> => {
  const response = await axios.post(strapiPath(`/posts/${postId}/comment`), {
    content,
  });
  return response.data;
};

export const getComments = async (postId: string): Promise<Comment[]> => {
  const response = await axios.get(strapiPath(`/posts/${postId}/comments`));
  return response.data;
};

export const uploadImage = async (file: File): Promise<any> => {
  const body = new FormData();
  body.append('files', file);
  const response = await axios.post(strapiPath('/upload'), body, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data;
};
