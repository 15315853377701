/* eslint-disable no-underscore-dangle */
import { CourseInteractions, COURSE_QUIZ_TYPE, PageContent } from '../types';

export default function checkQuiz(pageContent: PageContent, interactions: CourseInteractions) {
  return pageContent.contents.map((content) => {
    if (content.__component === COURSE_QUIZ_TYPE && !interactions.quiz[content.quiz_header]) {
      return false;
    }
    return true;
  }).every((x) => x);
}
