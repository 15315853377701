import React, { useCallback, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
  Container,
  MenuItem,
  Toolbar,
  Link,
  IconButton,
  Grow,
  Paper,
  Popper,
  ClickAwayListener,
  MenuList,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { logout } from '../../redux/actions/user';
import { RootState } from '../../redux/reducers';
import Logo from '../../assets/images/logo.png';

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#fff',
    zIndex: 100,
  },
  root: {
    flexGrow: 1,
  },
  logo: {
    width: 240,
  },
  toolbar: {
    width: '100%',
    padding: '12px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuButton: {
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: 'white',

    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  title: {
    flexGrow: 1,
  },
}));

const UserInfo = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  // const patient = useSelector((state: RootState) => state.patient);
  const authJWT = useSelector((state: RootState) => state.authJWT);
  const { data: user } = useSelector((state: RootState) => state.patient);
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  console.log(user);

  const isAdministrator = user?.role?.type === 'administrator';

  const onLogoutClick = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
    dispatch(logout());
  }, [dispatch]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current
      && anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  if (!authJWT) {
    return null;
  }

  return (
    <Container className={classes.container}>
      <Toolbar className={classes.toolbar}>
        <NavLink to="/">
          <img className={classes.logo} src={Logo} alt="Logo" />
        </NavLink>
        <div>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="primary"
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <MenuIcon />
          </IconButton>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem>
                        <Link component={NavLink} to="/" onClick={handleClose}>
                          Home
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link component={NavLink} to="/profile" onClick={handleClose}>
                          Profile
                        </Link>
                      </MenuItem>
                      {user?.membership === 'free' && (
                        <MenuItem>
                          <Link component={NavLink} to="/membership" onClick={handleClose}>
                            Upgrade
                          </Link>
                        </MenuItem>
                      )}
                      {isAdministrator && (
                        <>
                          <MenuItem>
                            <Link component={NavLink} to="/courses">
                              Courses
                            </Link>
                          </MenuItem>
                          <MenuItem>
                            <Link component={NavLink} to="/community">
                              Community
                            </Link>
                          </MenuItem>
                        </>
                      )}
                      <MenuItem onClick={onLogoutClick}>Logout</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </Toolbar>
    </Container>
  );
};

export default UserInfo;
