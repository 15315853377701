import {
  Box, Button, Grid, LinearProgress,
} from '@material-ui/core';
import FeedItem from './FeedItem';
import { Post } from '../../types';

interface FeedListProps {
  userId: string;
  posts?: Post[];
  hasMore?: boolean;
  loading?: boolean;
  onLoadMore?: () => void;
  onFollow: (_id: string) => void;
}

const FeedList = ({
  userId,
  posts = [],
  hasMore = true,
  loading = false,
  onFollow,
  onLoadMore,
}: FeedListProps) => (
  <Box paddingBottom={4}>
    {posts?.map((post) => (
      <Box key={post.id} marginBottom={2}>
        {post.author && (
          <FeedItem
            post={post}
            isLiked={
              (post.liked || []).filter((author) => author.id === userId).length
              > 0
            }
            isHidden={post.author && userId === post.author.id}
            onFollow={() => onFollow(post.id)}
          />
        )}
      </Box>
    ))}
    {loading && <LinearProgress />}
    {!loading && hasMore && (
      <Grid justify="center">
        <Button variant="contained" color="primary" onClick={onLoadMore}>
          Load More
        </Button>
      </Grid>
    )}
  </Box>
);

export default FeedList;
