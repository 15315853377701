import { Questionnaire } from '../../types';

// Action constants
export const QUESTIONNAIRE_REQUEST = 'QUESTIONNAIRE_REQUEST';
export const QUESTIONNAIRE_SUCCESS = 'QUESTIONNAIRE_SUCCESS';
export const QUESTIONNAIRE_ERROR = 'QUESTIONNAIRE_ERROR';

// Action builders
export const questionnaireRequest = () => ({
  type: QUESTIONNAIRE_REQUEST,
});

export const questionnaireSuccess = (questionnaires: Questionnaire | null) => ({
  type: QUESTIONNAIRE_SUCCESS,
  questionnaires,
});

export const questionnaireError = (error: any) => ({
  type: QUESTIONNAIRE_ERROR,
  error,
});
