/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import authApiPath, { strapiPath } from './authApiPath';
import { Patient } from '../types';

interface LoginResponse {
  user: Patient;
  token: string;
}

export const loginPatient = async (
  email: string,
  password: string,
): Promise<LoginResponse> => {
  try {
    const response = await axios.post(strapiPath('/auth/local'), {
      identifier: email,
      password,
    });

    const { user, jwt } = response.data;

    return {
      user,
      token: jwt,
    };
  } catch (error: any) {
    throw new Error(error);
  }
};

export const forgotPassword = async (
  email: string,
) => {
  try {
    await axios.post(strapiPath('/auth/forgot-password'), {
      email,
    });
  } catch (error: any) {
    throw new Error(error);
  }
};

export const resetPassword = async (
  code: string,
  password: string,
  passwordConfirmation: string,
) => {
  try {
    await axios.post(strapiPath('/auth/reset-password'), {
      code,
      password,
      passwordConfirmation,
    });
  } catch (error: any) {
    throw new Error(error);
  }
};

export const loadPatient = async (): Promise<Patient> => {
  try {
    const response = await axios.get(strapiPath('/users/me'));

    const user = response.data;

    return user;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const createSubscription = async (
  paymentMethodId: string,
  user: any,
): Promise<any> => {
  try {
    const response = await axios.post(authApiPath('/subscription'), {
      user,
      patient_id: user.id,
      paymentMethodId,
    });

    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};
