import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  Box,
  Typography,
  Paper,
  Button,
  Select,
  FormControl,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import style from '../style';
import loadPatient from '../../redux/thunks/loadPatient';
import { RootState } from '../../redux/reducers';
import { createSubscription } from '../../services/auth';
import { updateMembership } from '../../services/content';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY || '');

const useStyles = makeStyles(style);

interface CheckoutFormProps {
  onConfirm: () => void;
}

const CheckoutForm: React.FC<CheckoutFormProps> = ({ onConfirm }) => {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const profile = useSelector((state: RootState) => state.patient.data);
  const [payError, setPayError] = useState<boolean>(false);
  const [cardComplete, setCardComplete] = useState<boolean>(false);
  const [paying, setPaying] = useState<boolean>(false);

  const onCardChange = (e: any) => {
    setCardComplete(!e.error && e.complete);
  };

  const onPay = async () => {
    setPayError(false);
    setPaying(true);
    try {
      if (stripe && elements) {
        const element = elements.getElement(CardElement);

        if (element) {
          const result = await stripe.createPaymentMethod({
            type: 'card',
            card: element,
            billing_details: {
              name: `${profile?.first_name} ${profile?.last_name}`,
            },
          });

          if (result.error) {
            console.log(result.error?.message);
            setPayError(true);
          } else {
            const subscription = await createSubscription(
              result.paymentMethod.id,
              profile,
            );
            if (subscription.subscription) {
              await updateMembership(subscription.subscription.customer);
              onConfirm();
            }
          }
        } else {
          setPayError(true);
        }
      } else {
        setPayError(true);
      }
    } catch (error) {
      setPayError(true);
    } finally {
      setPaying(false);
    }
  };

  // const onApplyPromo = async () => {
  //   setPromoError(false);
  //   setPromoSuccess(false);
  //   try {
  //     const response = await applyPromocode(promo);

  //     if (response.success && response.amount) {
  //       setAmount(response.amount / 100);
  //       setPromoSuccess(true);
  //     } else {
  //       setPromoError(true);
  //     }
  //   } catch (error) {
  //     setPromoError(true);
  //   }
  // };

  return (
    <Box marginTop={4}>
      <FormControl variant="outlined" className={classes.formItem}>
        <Select native>
          <option value={20}>14-day Free Trial ($35/month after)</option>
        </Select>
      </FormControl>
      {/* {amount !== 0 && (
        <Box display="flex" alignItems="center" marginTop={2}>
          <TextField
            className={classes.formItem}
            value={promo}
            id="outlined-basic"
            label="Promo Code"
            variant="outlined"
            disabled={promoSuccess}
            onChange={(e) => setPromo(e.target.value)}
          />
          <Button
            className={classes.applyButton}
            variant="contained"
            color="primary"
            disabled={!promo || promoSuccess}
            onClick={onApplyPromo}
          >
            Apply
          </Button>
        </Box>
      )} */}
      <Box marginTop={2}>
        <CardElement
          className={classes.stripeContainer}
          onChange={onCardChange}
          options={{
            style: {
              base: {
                padding: '18.5px 14px',
                fontSize: '16px',
              },
            },
          }}
        />
      </Box>
      <Box marginTop={2} marginBottom={2}>
        <Button
          disabled={paying || (!stripe || !cardComplete)}
          variant="contained"
          color="primary"
          onClick={onPay}
        >
          {paying && (
            <CircularProgress className={classes.loadingBtn} size={25} />
          )}
          Subscribe
        </Button>
      </Box>
      {payError && (
        <Alert severity="error">There was an issue making payment.</Alert>
      )}
    </Box>
  );
};

const Payment: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const onConfirm = () => {
    dispatch(loadPatient());
    history.push('/');
  };

  return (
    <Paper variant="outlined" className={classes.panel}>
      <Typography
        className={classes.title}
        variant="h1"
        align="center"
        gutterBottom
      >
        Upgrade Your Membership
      </Typography>
      <Typography
        className={classes.subtitle}
        variant="h5"
        align="center"
        gutterBottom
      >
        Pay securely with a credit card and get started with your maternal
        wellness.
      </Typography>
      <Elements stripe={stripePromise}>
        <CheckoutForm onConfirm={onConfirm} />
      </Elements>
    </Paper>
  );
};

export default Payment;
