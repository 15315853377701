import { StoreCoursesList, STORE_COURSES_LIST } from '../actions/courses';
import { Logout, LOGOUT } from '../actions/user';
import { CourseIdentity } from '../../types';

const coursesList = (
  state: CourseIdentity[] = [],
  action: StoreCoursesList | Logout,
): CourseIdentity[] => {
  switch (action.type) {
    case STORE_COURSES_LIST:
      return action.courses;

    case LOGOUT:
      return [];

    default:
      return state;
  }
};

export default coursesList;
