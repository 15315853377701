import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  Container, Grid, Typography, Paper, Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ContentCard from '../../components/ContentCard';
import Skeleton from '../../components/Skeleton';
import { RootState } from '../../redux/reducers';
import loadToday from '../../redux/thunks/loadToday';
import loadAppointments from '../../redux/thunks/loadAppointments';
import loadServices from '../../redux/thunks/loadServices';
import loadQuestionnaires from '../../redux/thunks/loadQuestionnaires';
import { putUserMetaIndex } from '../../services/content';
import Appointments from './Appointments';
import Empowerment from './Empowerment';
import Schedule from './Schedule';
import style from '../style';

const useStyles = makeStyles(style);

const moodTypeQuestionMap: { [key: string]: string } = {
  Wellness: 'How are you feeling today?',
  Mental_Health: 'How are you feeling today?',
  Lactation: 'Are you satisfied with your breastfeeding/lactation results?',
  Nutrition: 'How encouraged are you about your nutritional habits?',
  Physical_Therapy: 'How is your body adapting to the physical changes?',
  Sleep_Training: "How's your baby sleeping through the night?",
  Couples: "How's the relationship with your partner going?",
  Lifestyle: 'How are you managing through your day to day?',
};

const Home = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { content, loading } = useSelector((state: RootState) => state.today);
  const { data: appointments, loading: appointmentLoading } = useSelector(
    (state: RootState) => state.appointments,
  );
  const { data: patient } = useSelector((state: RootState) => state.patient);
  const { data: questionnaires } = useSelector(
    (state: RootState) => state.questionnaires,
  );

  useEffect(() => {
    dispatch(loadToday());
    dispatch(loadAppointments());
    dispatch(loadServices());
    dispatch(loadQuestionnaires());
  }, [dispatch]);

  const onViewTodayContent = async () => {
    if (content.next) {
      await putUserMetaIndex(content.next.index);
    }
  };

  return (
    <Container fixed>
      <Paper variant="outlined" className={classes.panel}>
        <Typography
          className={classes.title}
          variant="h5"
          align="center"
          gutterBottom
        >
          The MaternalWell Program
        </Typography>
        <Typography
          className={classes.subtitle}
          variant="h5"
          align="center"
          gutterBottom
        >
          Your daily dose of information, inspiration, and motivation.
        </Typography>
        <Grid container spacing={3} justify="center" style={{ marginTop: 16 }}>
          <Grid item xs={12} sm={8}>
            {loading ? (
              <Skeleton />
            ) : (
              content.next && (
                <ContentCard
                  title={"Start Today's Material"}
                  courseName={content.next.course_name}
                  description={content.next.description}
                  image={content.next.Image?.url}
                  externalUrl={content.next.url}
                  courseUrl={
                    content.nextCourse
                      ? `/course/${content.nextCourse.slug}`
                      : undefined
                  }
                  onClick={onViewTodayContent}
                />
              )
            )}
          </Grid>
        </Grid>
      </Paper>
      <Paper variant="outlined" className={classes.panel}>
        <Typography
          className={classes.title}
          variant="h5"
          align="center"
          gutterBottom
        >
          Telehealth Sessions
        </Typography>
        <Typography
          className={classes.subtitle}
          variant="h5"
          align="center"
          gutterBottom
        >
          Convenient virtual sessions with vetted maternal specialists.
        </Typography>
        <Appointments
          loading={appointmentLoading}
          appointments={appointments}
        />
        <Schedule />
      </Paper>
      {(patient?.mood_check || patient?.questionnaire) && (
        <Paper variant="outlined" className={classes.panel}>
          <Typography
            className={classes.title}
            variant="h5"
            align="center"
            gutterBottom
          >
            Checking In
          </Typography>
          {patient?.mood_check && (
            <Grid container justify="center" style={{ marginTop: 16 }}>
              <Empowerment
                disabled={
                  !!patient.current_mood
                  && !!patient.mood_check_date
                  && moment(patient.mood_check_date).isSame(new Date(), 'day')
                }
                mood={
                  moment(patient.mood_check_date).isSame(new Date(), 'day')
                    ? patient.current_mood
                    : undefined
                }
                question={
                  content?.next?.type
                    ? moodTypeQuestionMap[content?.next?.type]
                    : 'How are you feeling today?'
                }
              />
            </Grid>
          )}
          {patient?.questionnaire && (
            <>
              <Typography variant="h6" align="center" gutterBottom>
                {questionnaires?.homeHeading || 'Complete short questionnaire'}
              </Typography>
              {questionnaires?.homeDescription && (
                <Typography align="center" gutterBottom>
                  {questionnaires?.homeDescription}
                </Typography>
              )}
              <Grid container justify="center" style={{ marginTop: 16 }}>
                <Button
                  component={Link}
                  to="/questionnaire"
                  variant="contained"
                  color="primary"
                >
                  {questionnaires?.homeButtonLabel || 'Go to Questionnaire'}
                </Button>
              </Grid>
            </>
          )}
        </Paper>
      )}
    </Container>
  );
};

Home.displayName = 'Home';

export default Home;
