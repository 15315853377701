import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Container,
  Paper,
  TextField,
  Box,
  FormControlLabel,
  Switch,
  Button,
  CircularProgress,
  Avatar,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { RootState } from '../../redux/reducers';
import style from '../style';
import { updateProfile } from '../../services/content';
import loadPatient from '../../redux/thunks/loadPatient';
import { uploadImage } from '../../services/community';

const useStyles = makeStyles(style);

type UserStateType = {
  firstName?: string;
  lastName?: string;
  type?: string;
  dueDate?: string;
  screenName?: string;
  allowContact?: boolean;
};

const ProfileView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data: patient, loading: patientLoading } = useSelector(
    (state: RootState) => state.patient,
  );
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<UserStateType>({
    firstName: patient?.first_name,
    lastName: patient?.last_name,
    type: patient?.type,
    dueDate: patient?.dueDate,
    screenName: patient?.screenName,
    allowContact: patient?.allowContact,
  });

  const onFieldChange = (fieldName: string) => (e: any) => {
    setUser((prev) => ({
      ...prev,
      [fieldName]: e.target.value,
    }));
  };

  const onDueDayChange = (value: MaterialUiPickersDate) => {
    setUser((prev) => ({
      ...prev,
      dueDate: value?.toISOString(),
    }));
  };

  useEffect(() => {
    setUser({
      firstName: patient?.first_name,
      lastName: patient?.last_name,
      type: patient?.type,
      dueDate: patient?.dueDate,
      screenName: patient?.screenName,
      allowContact: patient?.allowContact,
    });
  }, [patient]);

  const onSubmit = async () => {
    setLoading(true);
    try {
      const request: any = {
        ...user,
      };

      if (file) {
        const uploaded = await uploadImage(file);
        if (uploaded.length > 0) {
          request.profilePic = uploaded[0].id;
        }
      }

      await updateProfile(request);
      dispatch(loadPatient());
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  if (patientLoading) {
    return (
      <Container fixed>
        <Paper variant="outlined" className={classes.panel}>
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        </Paper>
      </Container>
    );
  }

  return (
    <Container fixed>
      <Paper variant="outlined" className={classes.panel}>
        <Typography
          className={classes.title}
          variant="h5"
          align="center"
          gutterBottom
        >
          Profile Settings
        </Typography>
        <Box marginTop={4}>
          <Box marginTop={2}>
            <TextField
              fullWidth
              id="first_name"
              label="First Name"
              value={user.firstName}
              variant="outlined"
              onChange={onFieldChange('firstName')}
            />
          </Box>
          <Box marginTop={2}>
            <TextField
              fullWidth
              id="last_name"
              label="Last Name"
              value={user.lastName}
              variant="outlined"
              onChange={onFieldChange('lastName')}
            />
          </Box>
          <Box marginTop={2}>
            <FormControlLabel
              control={(
                <Switch
                  checked={user.type === 'PT'}
                  onChange={(e) => setUser((prev) => ({
                    ...prev,
                    type: e.target.checked ? 'PT' : 'PP',
                  }))}
                  name="type"
                />
              )}
              label="Prenatal or Postpartum"
            />
          </Box>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Box marginTop={2}>
              <DatePicker
                autoOk
                fullWidth
                label="Due Date/Delivery Date"
                format="MM/dd/yyyy"
                variant="inline"
                inputVariant="outlined"
                value={user.dueDate}
                onChange={onDueDayChange}
              />
            </Box>
          </MuiPickersUtilsProvider>
          <Box marginTop={2}>
            <TextField
              fullWidth
              id="screen_name"
              value={user.screenName}
              label="Screen Name(Optional)"
              variant="outlined"
              onChange={onFieldChange('screenName')}
            />
          </Box>
          <Box marginTop={2}>
            <FormControlLabel
              control={(
                <Switch
                  checked={user.allowContact}
                  onChange={(e) => setUser((prev) => ({
                    ...prev,
                    allowContact: e.target.checked,
                  }))}
                  name="gilad"
                />
              )}
              label="Allow members to contact?"
            />
          </Box>
          <Box marginTop={2} display="flex">
            <input
              accept="image/*"
              className={classes.input}
              id="icon-button-file"
              type="file"
              onChange={(e) => e.target.files && setFile(e.target.files[0])}
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="icon-button-file">
              <Button color="primary" variant="outlined" component="span">
                Upload Profile Photo
              </Button>
            </label>
            {!file && patient?.profilePic && (
              <Avatar
                className={classes.avatar}
                alt="Avatar"
                src={patient.profilePic.url}
              />
            )}
            {file && (
              <Avatar
                className={classes.avatar}
                alt="Avatar"
                src={URL.createObjectURL(file)}
              />
            )}
          </Box>
          <Box marginTop={2}>
            <Button
              startIcon={loading ? <CircularProgress size={20} /> : null}
              color="primary"
              variant="contained"
              disabled={loading}
              onClick={onSubmit}
            >
              Save Profile
            </Button>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default ProfileView;
