import { Content } from '../../types';

// Action constants
export const CONTENTS_REQUEST = 'CONTENTS_REQUEST';
export const CONTENTS_SUCCESS = 'CONTENTS_SUCCESS';
export const CONTENTS_ERROR = 'CONTENTS_ERROR';

// Action builders
export const contentsRequest = () => ({
  type: CONTENTS_REQUEST,
});

export const contentsSuccess = (contents: Content[]) => ({
  type: CONTENTS_SUCCESS,
  contents,
});

export const contentsError = (error: any) => ({
  type: CONTENTS_ERROR,
  error,
});
