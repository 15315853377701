import { Patient } from '../../types';

// Action constants
export const LOGOUT = 'LOGOUT';
export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';

// Types
export interface AuthSuccess {
  type: typeof AUTH_SUCCESS;
  payload: Patient;
}

export interface Logout {
  type: typeof LOGOUT;
}

export const authRequest = () => ({
  type: AUTH_REQUEST,
});

export const authSuccess = (user: Patient): AuthSuccess => ({
  type: AUTH_SUCCESS,
  payload: user,
});

export const authError = () => ({
  type: AUTH_ERROR,
});

export const logout = (): Logout => ({ type: LOGOUT });
