import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { interactWithCourse } from '../../redux/actions/courses';
import { RootState } from '../../redux/reducers';
import { QuizContent, QuizInteraction } from '../../types';
import QuizOption from './QuizOption';

import styles from './styles.module.scss';

interface CourseQuizProps {
  quiz: QuizContent;
}

const CourseQuiz = ({ quiz }: CourseQuizProps) => {
  const dispatch = useDispatch();
  const interactions = useSelector((state: RootState) => state.interactions);

  const selection = interactions.quiz[quiz.quiz_header]?.selectedAnswer || null;

  const onSelectionMade = useCallback(
    (selectedAnswer: string) => {
      let value: QuizInteraction | null;
      if (selectedAnswer !== selection) {
        value = {
          question: quiz.quiz_choice,
          selectedAnswer,
        };
      } else {
        value = null;
      }
      dispatch(interactWithCourse('quiz', quiz.quiz_header, value));
    },
    [quiz, dispatch, selection],
  );

  const optionNodes = quiz.quiz_choice.map((option) => {
    if (!option) {
      return null;
    }

    return (
      <QuizOption
        name={quiz.quiz_header}
        option={option.answer}
        value={option.id}
        checked={option.id === selection}
        onClick={onSelectionMade}
        key={option.id}
      />
    );
  }).filter((x) => x);

  return (
    <div className={styles.quiz}>
      {quiz.quiz_header}
      <div className={styles.options}>
        {optionNodes}
      </div>
    </div>
  );
};

CourseQuiz.displayName = 'CourseQuiz';

export default CourseQuiz;
