import { AppThunk } from '../../types';
import {
  appointmentsRequest,
  appointmentsSuccess,
  appointmentsError,
} from '../actions/appointments';
import { loadAppointments as getAppointments } from '../../services/appointment';

const loadAppointments = (): AppThunk => async (dispatch) => {
  dispatch(appointmentsRequest());

  try {
    const appointments = await getAppointments();

    if (appointments) {
      dispatch(appointmentsSuccess(appointments));
    } else {
      dispatch(appointmentsSuccess([]));
    }
  } catch (error) {
    dispatch(appointmentsError(error));
  }
};

export default loadAppointments;
