import { LOGOUT, Logout } from '../actions/user';
import { REGISTER_AUTH_JWT, RegisterAuthJWT } from '../actions/jwt';
import storedReducer from '../storedReducer';

type AuthJWTState = string | null;

const authJWT = (
  state: AuthJWTState = null,
  action: Logout | RegisterAuthJWT,
): AuthJWTState => {
  switch (action.type) {
    case LOGOUT:
      return null;

    case REGISTER_AUTH_JWT:
      return action.token;

    default:
      return state;
  }
};

export default storedReducer(authJWT, 'authJWT');
