import React from 'react';
import { Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import style from '../style';

const useStyles = makeStyles(style);

const Instructions: React.FC = () => {
  const classes = useStyles();

  return (
    <Paper variant="outlined" className={classes.panel}>
      <Typography
        className={classes.subtitle}
        variant="h5"
        align="left"
        gutterBottom
      >
        We look forward to seeing you at your scheduled appointment time. The
        HIPPA compliant audio/video session is fairly straightforward and easy
        to connect. You don't need any app downloads, pre-registration, logins,
        or anything - just click the waiting room link and you are ready to go!
        To be ready ahead of your appointment:
      </Typography>
      <ul className={classes.instruction}>
        <li>
          <Typography
            className={classes.subtitle}
            variant="h5"
            align="left"
            gutterBottom
          >
            <strong>Device.</strong>
            {' '}
            Any device with an updated browser, camera
            and microphone should work but we've seen the best user experience
            with smartphones.
          </Typography>
        </li>
        <li>
          <Typography
            className={classes.subtitle}
            variant="h5"
            align="left"
            gutterBottom
          >
            <strong>Internet Connectivity.</strong>
            {' '}
            Please allow one hour in a
            private setting with
            {' '}
            <i>good Internet connectivity</i>
            .
          </Typography>
        </li>
        <li>
          <Typography
            className={classes.subtitle}
            variant="h5"
            align="left"
            gutterBottom
          >
            <strong>Microphone/Camera.</strong>
            {' '}
            Your browser must be able to
            access yoru webcam and microphone. Simply clicking "Allow" when
            prompted should be enough but you can check Settings/Permissions on
            your browser to see if enabled.
          </Typography>
        </li>
        <li>
          <Typography
            className={classes.subtitle}
            variant="h5"
            align="left"
            gutterBottom
          >
            <strong>Browser.</strong>
            {' '}
            You can use Google Chrome, Firefox, or
            Microsoft Edge browsers.
          </Typography>
        </li>
      </ul>
    </Paper>
  );
};

export default Instructions;
