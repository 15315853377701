import { compose, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import storedReducer from './storedReducer';
import reducers from './reducers';

const initialState = {
  ...(JSON.parse(window.localStorage.getItem(storedReducer.storageKey) || '{}')),
};

const composeEnhancers = (
  (process.env.NODE_ENV !== 'production' || process.env.REACT_APP_REDUX_DEVTOOLS === 'true')
  && typeof window !== 'undefined'
  && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
) || compose;

export default createStore(reducers, initialState, composeEnhancers(
  applyMiddleware(thunk),
));
