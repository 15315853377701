const shuffle = (items: any[]) => {
  const cloneItems = [...items];
  let currentIndex = cloneItems.length;
  let randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    [cloneItems[currentIndex], cloneItems[randomIndex]] = [
      cloneItems[randomIndex],
      cloneItems[currentIndex],
    ];
  }

  return cloneItems;
};

export default shuffle;
