import axios, { AxiosRequestConfig } from 'axios';
import storedReducer from '../redux/storedReducer';

axios.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const storage = JSON.parse(
      window.localStorage.getItem(storedReducer.storageKey) || '{}',
    );

    if (storage.authJWT) {
      config.headers.Authorization = `Bearer ${storage.authJWT}`;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);
