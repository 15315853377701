import { AppThunk } from '../../types';
import {
  authRequest, authSuccess, authError, logout,
} from '../actions/user';
import { registerAuthJWT } from '../actions/jwt';
import * as authApi from '../../services/auth';

const loginPatient = (email: string, password: string): AppThunk => async (dispatch) => {
  dispatch(authRequest());
  try {
    const { user, token } = await authApi.loginPatient(email, password);
    dispatch(authSuccess(user));
    dispatch(registerAuthJWT(token));

    return { user, token };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    dispatch(logout());
    dispatch(authError());
  }
};

export default loginPatient;
