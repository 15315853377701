import { AppThunk } from '../../types';
import {
  postsRequest,
  postsSuccess,
  postsError,
  newPostRequest,
  newPostError,
  newPostSuccess,
  likePostRequest,
  likePostSuccess,
  likePostError,
  commentPostRequest,
  commentPostSuccess,
  commentPostError,
  getCommentsRequest,
  getCommentsSuccess,
  getCommentsError,
} from '../actions/posts';
import {
  createPost,
  loadPosts as fetchPosts,
  likePost as followPost,
  commentPost as postComment,
  getComments as fetchComments,
} from '../../services/community';

export const loadPosts = (offset: number = 0): AppThunk => async (dispatch) => {
  dispatch(postsRequest());

  try {
    const appointments = await fetchPosts(offset);

    if (appointments) {
      dispatch(postsSuccess(appointments, offset));
    } else {
      dispatch(postsSuccess([]));
    }
  } catch (error) {
    dispatch(postsError(error));
  }
};

export const createPosts = (request: any): AppThunk => async (dispatch) => {
  dispatch(newPostRequest());

  try {
    const newPost = await createPost(request);

    dispatch(newPostSuccess(newPost));
    dispatch(loadPosts());
  } catch (error) {
    dispatch(newPostError(error));
  }
};

export const likePost = (id: string): AppThunk => async (dispatch) => {
  dispatch(likePostRequest());

  try {
    const post = await followPost(id);

    dispatch(likePostSuccess(id, post.liked || []));
  } catch (error) {
    dispatch(likePostError(error));
  }
};

export const commentPost = (id: string, comment: string): AppThunk => async (dispatch) => {
  dispatch(commentPostRequest());

  try {
    const post = await postComment(id, comment);

    dispatch(commentPostSuccess(id, post.comments || []));
  } catch (error) {
    dispatch(commentPostError(error));
  }
};

export const getComments = (id: string): AppThunk => async (dispatch) => {
  dispatch(getCommentsRequest());

  try {
    const comments = await fetchComments(id);

    dispatch(getCommentsSuccess(id, comments));
  } catch (error) {
    dispatch(getCommentsError(error));
  }
};
