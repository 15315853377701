import { AppThunk } from '../../types';
import {
  servicesRequest,
  servicesSuccess,
  servicesError,
} from '../actions/services';
import { loadServices as getServices } from '../../services/appointment';

const loadServices = (): AppThunk => async (dispatch) => {
  dispatch(servicesRequest());

  try {
    const services = await getServices();

    if (services) {
      dispatch(servicesSuccess(services));
    } else {
      dispatch(servicesSuccess([]));
    }
  } catch (error) {
    dispatch(servicesError(error));
  }
};

export default loadServices;
