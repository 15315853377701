import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    minWidth: 375,
    maxWidth: '100%',
    padding: 16,

    [theme.breakpoints.down('sm')]: {
      minWidth: 0,
    },
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {},
  media: {
    height: 230,
  },
}));

interface ContentCardProps {
  title?: string;
  courseName?: string;
  image?: string;
  description?: string;
  externalUrl?: string;
  courseUrl?: string;
  onClick?: () => void;
}

const ContentCard: React.FC<ContentCardProps> = ({
  title,
  courseName,
  image,
  description,
  externalUrl,
  courseUrl,
  onClick,
}: ContentCardProps) => {
  const classes = useStyles();

  return (
    <Card className={classes.root} variant="outlined">
      <CardMedia
        className={classes.media}
        image={
          image
          || 'https://course-cms.s3.us-west-2.amazonaws.com/MH_3_89cb5a2498.jpeg'
        }
        title={description}
      />
      <CardContent>
        <Typography
          className={classes.pos}
          align="center"
          color="textSecondary"
        >
          {courseName}
        </Typography>
        {/* <Typography variant="body2" align="center" component="p">
          {description}
        </Typography> */}
      </CardContent>
      <CardActions style={{ padding: 0 }}>
        {courseUrl ? (
          <Link
            to={courseUrl}
            style={{ textDecoration: 'none', margin: 'auto' }}
          >
            <Button variant="contained" color="primary" onClick={onClick}>
              {title}
            </Button>
          </Link>
        ) : (
          <a
            href={externalUrl}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'none', margin: 'auto' }}
          >
            <Button variant="contained" color="primary" onClick={onClick}>
              {title}
            </Button>
          </a>
        )}
      </CardActions>
    </Card>
  );
};

export default ContentCard;
