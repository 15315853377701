import courseApiPath from '../../services/courseApiPath';
import { ImageContent } from '../../types';

import styles from './styles.module.scss';

interface CourseImageProps {
  content: ImageContent;
}

const CourseImage = ({ content }: CourseImageProps) => (content.image ? (
  <img
    className={styles.image}
    src={courseApiPath(content.image.url)}
    alt={content.image.alternativeText}
  />
) : null);

CourseImage.displayName = 'CourseImage';

export default CourseImage;
