import { AppThunk } from '../../types';
import { startLoading, stopLoading } from '../actions/loading';
import { storeCoursesList } from '../actions/courses';
import * as coursesApi from '../../services/courses';

const loadCoursesList = (): AppThunk => async (dispatch) => {
  dispatch(startLoading());
  const coursesList = await coursesApi.loadCoursesList();
  if (coursesList && coursesList.length) {
    dispatch(storeCoursesList(coursesList));
  }
  dispatch(stopLoading());
};

export default loadCoursesList;
