import { AppThunk } from '../../types';
import {
  questionnaireRequest,
  questionnaireSuccess,
  questionnaireError,
} from '../actions/questionnaires';
import { loadQuestionnaires as getQuestionnaires } from '../../services/questionnaire';

const loadQuestionnaires = (): AppThunk => async (dispatch) => {
  dispatch(questionnaireRequest());

  try {
    const questionnaires = await getQuestionnaires();

    if (questionnaires) {
      dispatch(questionnaireSuccess(questionnaires));
    } else {
      dispatch(questionnaireSuccess(null));
    }
  } catch (error) {
    console.log(error);
    dispatch(questionnaireError(error));
  }
};

export default loadQuestionnaires;
