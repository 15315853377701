import axios from 'axios';
import { strapiPath } from './authApiPath';
import { Content } from '../types';

export const loadToday = async (): Promise<{
  next: Content | null;
  prev: Content | null;
}> => {
  const response = await axios.get(strapiPath('/contents/today'));
  const responseData = await response.data;

  return responseData;
};

export const loadContents = async (): Promise<Content[]> => {
  const response = await axios.get(strapiPath('/contents'));
  const responseData = await response.data;

  return responseData;
};

export const putUserMetaIndex = async (index: number): Promise<void> => {
  const response = await axios.put(strapiPath('/contents/index'), { index });
  const responseData = await response.data;

  return responseData;
};

export const updateProfile = async (request: any): Promise<void> => {
  const response = await axios.put(strapiPath('/posts/profile'), request);

  return response.data;
};

export const updateMembership = async (customerId: string): Promise<void> => {
  const response = await axios.put(strapiPath('/posts/membership'), {
    customer_id: customerId,
  });

  return response.data;
};
