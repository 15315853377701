import {
  BottomNavigation,
  BottomNavigationAction,
  Container,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  HomeOutlined as HomeIcon,
  AddCircle as PlusIcon,
  FavoriteBorder as FavoriteIcon,
} from '@material-ui/icons';
import { useHistory } from 'react-router';
import FeedList from '../../components/Feed/FeedList';
import NewFeed from '../../components/Feed/NewFeed';
import { RootState } from '../../redux/reducers';
import { createPosts, likePost, loadPosts } from '../../redux/thunks/posts';

const Community = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [offset, setOffset] = useState<number>(0);
  const [focusInput, setFocusInput] = useState<boolean>(false);
  const posts = useSelector((state: RootState) => state.posts);
  const patient = useSelector((state: RootState) => state.patient);

  useEffect(() => {
    dispatch(loadPosts(offset));
  }, [dispatch, offset]);

  const onPostFeed = (request: any) => {
    dispatch(createPosts(request));
  };

  const onFollow = (id: string) => {
    dispatch(likePost(id));
  };

  const onLoadMore = () => {
    setOffset((prev) => prev + 10);
  };

  return (
    <>
      <Container maxWidth="md" style={{ paddingBottom: 60 }}>
        <NewFeed
          focus={focusInput}
          onFocused={() => setFocusInput(false)}
          onSubmit={onPostFeed}
        />
        <FeedList
          userId={patient.data?.id || ''}
          posts={posts.data}
          loading={posts.fetchStatus === 'pending'}
          onLoadMore={onLoadMore}
          onFollow={onFollow}
        />
      </Container>
      <BottomNavigation
        value={1}
        style={{
          width: '100%',
          position: 'fixed',
          bottom: 0,
          boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 8px',
        }}
      >
        <BottomNavigationAction
          label="Home"
          icon={<HomeIcon />}
          onClick={() => history.push('/')}
        />
        <BottomNavigationAction
          showLabel={false}
          icon={<PlusIcon fontSize="large" />}
          onClick={() => setFocusInput(true)}
        />
        <BottomNavigationAction label="Favorite" icon={<FavoriteIcon />} />
      </BottomNavigation>
    </>
  );
};

export default Community;
