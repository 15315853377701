import { AppThunk } from '../../types';
import {
  contentTodayRequest,
  contentTodaySuccess,
  contentTodayError,
} from '../actions/today';
import { loadToday as getToday } from '../../services/content';

const loadToday = (): AppThunk => async (dispatch) => {
  dispatch(contentTodayRequest());

  try {
    const content = await getToday();

    if (content) {
      dispatch(contentTodaySuccess(content));
    } else {
      dispatch(contentTodaySuccess({ prev: null, next: null }));
    }
  } catch (error) {
    dispatch(contentTodayError(error));
  }
};

export default loadToday;
