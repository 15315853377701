import {
  CONTENTS_REQUEST,
  CONTENTS_SUCCESS,
  CONTENTS_ERROR,
} from '../actions/contents';
import { Content } from '../../types';

interface ContentsState {
  loading: boolean;
  data: Content[];
  error: string | null;
}

const contents = (
  state: ContentsState = { loading: false, data: [], error: null },
  action: any,
): ContentsState => {
  switch (action.type) {
    case CONTENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CONTENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.contents,
      };

    case CONTENTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default contents;
