import {
  QUESTIONNAIRE_REQUEST,
  QUESTIONNAIRE_SUCCESS,
  QUESTIONNAIRE_ERROR,
} from '../actions/questionnaires';
import { Questionnaire } from '../../types';

interface QuestionnaireState {
  loading: boolean;
  data: Questionnaire | null;
  error: string | null;
}

const questionnaires = (
  state: QuestionnaireState = { loading: false, data: null, error: null },
  action: any,
): QuestionnaireState => {
  switch (action.type) {
    case QUESTIONNAIRE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.questionnaires,
      };

    case QUESTIONNAIRE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default questionnaires;
