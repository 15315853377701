import MarkdownText from '../MarkdownText';
import { TextContent } from '../../types';

import styles from './styles.module.scss';

interface CourseTextProps {
  content: TextContent;
}

const CourseText = ({ content }: CourseTextProps) => (
  <MarkdownText className={styles.text}>
    {content.text}
  </MarkdownText>
);

CourseText.displayName = 'CourseText';

export default CourseText;
