import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  CssBaseline,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Paper,
  Grid,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { RootState } from '../../redux/reducers';
import loginPatient from '../../redux/thunks/loginPatient';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage:
      'url(https://maternalwell.com/wp-content/uploads/2019/06/banner-2-e1612646602556.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bottom: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const Login = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const authJWT = useSelector((state: RootState) => state.authJWT);
  const { loading, error } = useSelector((state: RootState) => state.patient);
  // const patient = useSelector((state: RootState) => state.patient);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const onSubmit = (e: any) => {
    e.preventDefault();
    dispatch(loginPatient(email, password));
  };

  if (authJWT) {
    return <Redirect to="/" />;
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={8} className={classes.image} />
      <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} noValidate onSubmit={onSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              autoFocus
              id="email"
              label="Email Address"
              name="email"
              value={email}
              onChange={(e: any) => setEmail(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={password}
              onChange={(e: any) => setPassword(e.target.value)}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              className={classes.submit}
              disabled={loading}
            >
              Sign In
            </Button>
            <div className={classes.bottom}>
              <span>
                New User? &nbsp;
                <a href="https://emr.maternalwell.com/join-free-membership">
                  Sign Up Here
                </a>
              </span>
              <Link to="/forgot-password">Forgot password?</Link>
            </div>
          </form>
          {loading && <CircularProgress />}
          {error && (
            <Alert elevation={6} variant="filled" severity="error">
              Username or Password do not match!
            </Alert>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

Login.displayName = 'Login';

export default Login;
