import { useCallback, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface QuizOptionProps {
  name: string;
  option: string;
  value: string;
  checked: boolean;
  // eslint-disable-next-line no-unused-vars
  onClick: (value: string) => void;
}

const QuizOption = ({
  name, option, value, checked, onClick,
}: QuizOptionProps) => {
  const optionId = useMemo(() => uuidv4(), []);
  const handleClick = useCallback(() => onClick(value), [onClick, value]);

  return (
    <>
      <input
        name={name}
        id={optionId}
        value={value}
        checked={checked}
        type="radio"
        onClick={handleClick}
        onChange={handleClick}
      />
      <label htmlFor={optionId}>{option}</label>
    </>
  );
};

QuizOption.displayName = 'QuizOption';

export default QuizOption;
