import { useState } from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import { putUserMetaMood } from '../../services/questionnaire';

const useStyles = makeStyles(() => createStyles({
  moodItem: (props: { hoverColor: string }) => ({
    color: '#cccccc',
    fontSize: 60,
    cursor: 'pointer',

    '&:hover': {
      color: props.hoverColor,
    },
  }),
  moodItemChecked: (props: { hoverColor: string }) => ({
    color: props.hoverColor,
    fontSize: 60,
    cursor: 'pointer',
  }),
}));

const moodIcons: { icon: any; label: string; color: string }[] = [
  {
    icon: SentimentVeryDissatisfiedIcon,
    label: 'Very Dissatisfied',
    color: '#0d47a1',
  },
  {
    icon: SentimentDissatisfiedIcon,
    label: 'Dissatisfied',
    color: '#2196f3',
  },
  {
    icon: SentimentSatisfiedIcon,
    label: 'Neutral',
    color: '#ffeb3b',
  },
  {
    icon: SentimentSatisfiedAltIcon,
    label: 'Satisfied',
    color: '#4caf50',
  },
  {
    icon: SentimentVerySatisfiedIcon,
    label: 'Very Satisfied',
    color: '#357a38',
  },
];

interface EmpowermentProps {
  question?: string;
  disabled?: boolean;
  mood?: string;
}

const MoodItem = ({
  icon,
  color,
  checked,
  onClick,
}: {
  icon: any;
  color: string;
  checked: boolean;
  onClick: () => void;
}) => {
  const style = useStyles({ hoverColor: color });
  const IconComponent = icon;

  return (
    <IconComponent
      className={checked ? style.moodItemChecked : style.moodItem}
      onClick={onClick}
    />
  );
};

const Empowerment = ({ mood, question = 'How are you feeling today?', disabled }: EmpowermentProps) => {
  const [rating, setRating] = useState<string | null>(mood || null);
  const [loading, setLoading] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);

  const onSubmitMood = async (value?: string) => {
    if (value) {
      setLoading(true);
      await putUserMetaMood(value);
      setLoading(false);
      setComplete(true);
    }
  };

  return (
    <Box
      component="fieldset"
      mb={3}
      borderColor="transparent"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant="h6" align="center" gutterBottom>
        {question}
      </Typography>
      <Box display="flex">
        {moodIcons.map((item) => (
          <Box key={item.label}>
            <MoodItem
              icon={item.icon}
              color={item.color}
              checked={rating === item.label}
              onClick={() => !disabled && !complete && setRating(item.label)}
            />
          </Box>
        ))}
      </Box>
      <Box marginTop={2}>
        {rating && !disabled && (
          <Button
            disabled={loading || complete}
            size="small"
            variant="contained"
            color="primary"
            onClick={() => onSubmitMood(rating)}
          >
            {complete ? 'Submited' : 'Submit'}
          </Button>
        )}
      </Box>
    </Box>
  );
};

Empowerment.displayName = 'Empowerment';

export default Empowerment;
