import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Container, Box, Grid } from '@material-ui/core';
import ContentCard from '../../components/ContentCard';
import Skeleton from '../../components/Skeleton';
import { RootState } from '../../redux/reducers';
import loadContents from '../../redux/thunks/loadContents';
import { Content } from '../../types';

const Contents = () => {
  const dispatch = useDispatch();
  const { data: contents, loading } = useSelector((state: RootState) => state.contents);

  useEffect(() => {
    dispatch(loadContents());
  }, [dispatch]);

  const renderContentCard = (title: string, item: Content) => (
    <ContentCard
      title={title}
      courseName={item.course_name}
      description={item.description}
      externalUrl={item.url}
    />
  );

  return (
    <Container fixed>
      <Box paddingTop={3}>
        {loading ? (
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Skeleton />
            </Grid>
            <Grid item xs={4}>
              <Skeleton />
            </Grid>
            <Grid item xs={4}>
              <Skeleton />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={3}>
            {contents.map((content, key) => (
              <Grid item xs={4} key={key}>
                {renderContentCard(`Day ${content.day}`, content)}
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Container>
  );
};

Contents.displayName = 'Contents';

export default Contents;
