import axios from 'axios';
import { strapiPath } from './authApiPath';
import { Questionnaire } from '../types';

export const loadQuestionnaires = async (): Promise<Questionnaire> => {
  const response = await axios.get(strapiPath('/questionnaires/today'));
  const responseData = await response.data;

  return responseData;
};

export const putUserMetaQuestionnaire = async (
  questionnaire: any,
): Promise<void> => {
  const response = await axios.put(strapiPath('/contents/questionnaire'), {
    questionnaire,
  });
  const responseData = await response.data;

  return responseData;
};

export const putUserMetaMood = async (
  mood: string,
): Promise<void> => {
  const response = await axios.put(strapiPath('/contents/mood'), {
    mood,
  });
  const responseData = await response.data;

  return responseData;
};

export const postQuestionnaire = async (
  title: string,
  questionnaire: any[],
): Promise<void> => {
  const response = await axios.post(strapiPath('/questionnaire-results'), {
    questionnaire: title,
    answers: questionnaire,
  });
  const responseData = await response.data;

  return responseData;
};
