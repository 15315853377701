import { combineReducers } from 'redux';
import authJWT from './authJWT';
import course from './course';
import coursesList from './coursesList';
import interactions from './interactions';
import loading from './loading';
import patient from './patient';
import today from './today';
import contents from './contents';
import appointments from './appointments';
import openings from './openings';
import services from './services';
import questionnaires from './questionnaires';
import posts from './posts';

const rootReducer = combineReducers({
  authJWT,
  course,
  coursesList,
  interactions,
  loading,
  patient,
  today,
  contents,
  appointments,
  openings,
  services,
  questionnaires,
  posts,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
