import {
  APPOINTMENTS_REQUEST,
  APPOINTMENTS_SUCCESS,
  APPOINTMENTS_ERROR,
} from '../actions/appointments';
import { Appointment } from '../../types';

interface AppointmentState {
  loading: boolean;
  data: Appointment[];
  error: string | null;
}

const appointments = (
  state: AppointmentState = { loading: false, data: [], error: null },
  action: any,
): AppointmentState => {
  switch (action.type) {
    case APPOINTMENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case APPOINTMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.appointments,
      };

    case APPOINTMENTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default appointments;
