import {
  SERVICES_REQUEST,
  SERVICES_SUCCESS,
  SERVICES_ERROR,
} from '../actions/services';
import { Service } from '../../types';

interface ServicesState {
  loading: boolean;
  data: Service[];
  error: string | null;
}

const services = (
  state: ServicesState = { loading: false, data: [], error: null },
  action: any,
): ServicesState => {
  switch (action.type) {
    case SERVICES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SERVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.services,
      };

    case SERVICES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default services;
