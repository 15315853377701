import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Box } from '@material-ui/core';
import loadCoursesList from '../../redux/thunks/loadCoursesList';
import { RootState } from '../../redux/reducers';

import styles from './styles.module.scss';

const CoursesList = () => {
  const coursesList = useSelector((state: RootState) => state.coursesList);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!coursesList.length) {
      dispatch(loadCoursesList());
    }
  }, [coursesList, dispatch]);

  return (
    <Container fixed>
      <Box paddingTop={3}>
        {coursesList.map(({ title, slug }) => (
          <Link
            className={styles.courseLink}
            key={slug}
            to={`/course/${slug}`}
          >
            {title}
          </Link>
        ))}
      </Box>
    </Container>
  );
};

CoursesList.displayName = 'CoursesList';

export default CoursesList;
