import React from 'react';
import {
  Box, Typography, Paper, Button, Container,
} from '@material-ui/core';
import { useLocation, Redirect, Link } from 'react-router-dom';
import qs from 'query-string';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowForward } from '@material-ui/icons';
import style from '../style';

const useStyles = makeStyles(style);

const Confirmed: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const { time } = qs.parse(location.search);

  if (!time) {
    return (
      <Redirect to="/" />
    );
  }

  return (
    <Container className={classes.root} fixed>
      <Paper variant="outlined" className={classes.panel}>
        <Typography
          className={classes.title}
          variant="h1"
          align="center"
          gutterBottom
        >
          Appointment confirmed
        </Typography>
        <Typography
          className={classes.subtitle}
          variant="h5"
          align="center"
          gutterBottom
        >
          Your appointment has been confirmed.
        </Typography>
        <Box padding={4}>
          <Typography
            className={classes.subtitle}
            variant="h5"
            align="center"
            gutterBottom
          >
            {`${moment(time).format('M/D/YYYY')} at ${moment(time).format('h:mm a')}`}
          </Typography>
        </Box>
        <Typography
          className={classes.subtitle}
          variant="h5"
          align="center"
          gutterBottom
        >
          You will receive a confirmation email with the appointment details within a few minutes.
        </Typography>
        <Box display="flex" justifyContent="center" alignItems="center" marginTop={4}>
          <Button
            className={classes.redirectButton}
            component={Link}
            variant="contained"
            color="primary"
            endIcon={<ArrowForward />}
            to="/instruction"
          >
            Next Step: Appointment Instructions
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default Confirmed;
