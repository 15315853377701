import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ListItem,
  ListItemSecondaryAction,
  TextField,
  IconButton,
  Theme,
  Avatar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Send as SendIcon } from '@material-ui/icons';
import { red, blue } from '@material-ui/core/colors';
import { RootState } from '../../redux/reducers';
import { commentPost } from '../../redux/thunks/posts';

interface NewCommentProps {
  id: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  submit: {
    color: blue[600],
  },
  media: {
    color: red[500],
  },
  input: {
    width: 0,
    visibility: 'hidden',
  },
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginRight: theme.spacing(2),
  },
  inputRoot: {
    padding: theme.spacing(2),
  },
  mainInput: {
    borderRadius: theme.spacing(3),
  }
}));

const NewComment = ({ id }: NewCommentProps) => {
  const dispatch = useDispatch();
  const [comment, setComment] = useState<string>('');
  const commentStatus = useSelector((state: RootState) => state.posts);
  const myProfile = useSelector((state: RootState) => state.patient.data);

  const classes = useStyles();

  const onSend = async () => {
    if (comment) {
      dispatch(commentPost(id, comment));
      setComment('');
    }
  };

  return (
    <ListItem disableGutters>
      <Avatar
        className={classes.avatar}
        alt="Avatar"
        src={myProfile?.profilePic?.url}
      >
        {myProfile?.first_name?.charAt(0)}
      </Avatar>
      <TextField
        fullWidth
        id="standard-basic"
        value={comment}
        variant="outlined"
        label="Add a comment..."
        InputProps={{
          className: classes.mainInput,
        }}
        classes={{
          // inputRoot: classes.inputRoot,
        }}
        onChange={(e) => setComment(e.target.value)}
        onKeyDown={(e: any) => {
          if (e.keyCode === 13) {
            onSend();
          }
        }}
      />
    </ListItem>
  );
};

export default NewComment;
