import {
  StartLoading, StopLoading, START_LOADING, STOP_LOADING,
} from '../actions/loading';

const loadingTickets = (
  state: number = 0,
  action: StartLoading | StopLoading,
): number => {
  switch (action.type) {
    case START_LOADING:
      return state + 1;

    case STOP_LOADING:
      return Math.max(state - 1, 0);

    default:
      return state;
  }
};

export default loadingTickets;
