import { AppThunk } from '../../types';
import {
  contentsRequest,
  contentsSuccess,
  contentsError,
} from '../actions/contents';
import { loadContents as getContents } from '../../services/content';

const loadContents = (): AppThunk => async (dispatch) => {
  dispatch(contentsRequest());

  try {
    const contents = await getContents();

    if (contents) {
      dispatch(contentsSuccess(contents));
    } else {
      dispatch(contentsSuccess([]));
    }
  } catch (error) {
    dispatch(contentsError(error));
  }
};

export default loadContents;
