import { Content } from '../../types';

// Action constants
export const CONTENT_TODAY_REQUEST = 'CONTENT_TODAY_REQUEST';
export const CONTENT_TODAY_SUCCESS = 'CONTENT_TODAY_SUCCESS';
export const CONTENT_TODAY_ERROR = 'CONTENT_TODAY_ERROR';

export interface ContentPayload {
  next: Content | null,
  prev: Content | null,
}

// Action builders
export const contentTodayRequest = () => ({
  type: CONTENT_TODAY_REQUEST,
});

export const contentTodaySuccess = (content: ContentPayload) => ({
  type: CONTENT_TODAY_SUCCESS,
  content,
});

export const contentTodayError = (error: any) => ({
  type: CONTENT_TODAY_ERROR,
  error,
});
