import React from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import qs from 'query-string';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PaymentView from './Payment';

import style from '../style';

const useStyles = makeStyles(style);

const Payment: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const { insurance_id: insuranceId, time } = qs.parse(location.search);

  if (!insuranceId || !time) {
    return <Redirect to="/" />;
  }

  return (
    <Container className={classes.root} fixed>
      <PaymentView insuranceId={Number(insuranceId)} time={String(time)} />
    </Container>
  );
};

export default Payment;
