import React from 'react';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Instructions from './Instructions';
import style from '../style';

const useStyles = makeStyles(style);

const Instruction: React.FC = () => {
  const classes = useStyles();

  return (
    <Container className={classes.root} fixed>
      <Instructions />
    </Container>
  );
};

export default Instruction;
