import { useMemo, useState } from 'react';
import moment from 'moment';
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  IconButton,
  Avatar,
  Typography,
  Collapse,
  List,
  Theme,
  Box,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  FavoriteBorder as FavoriteIcon,
  Favorite as FavoritedIcon,
  ChatBubbleOutline as CommentIcon,
  ShareOutlined as ShareIcon,
} from '@material-ui/icons';
import {
  red,
  // pink,
  // purple,
  // deepPurple,
  // deepOrange,
  // indigo,
  // blue,
  // lightBlue,
  // cyan,
  // teal,
  // green,
} from '@material-ui/core/colors';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Post } from '../../types';
import FeedCommentItem from './FeedCommentItem';
import NewComment from './NewComment';

interface FeedItemProps {
  post: Post;
  isLiked?: boolean;
  isHidden?: boolean;
  onFollow: () => void;
  onLoadComments?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 8px',
    borderRadius: theme.spacing(2),
  },
  avatar: {
    backgroundColor: red[500],
  },
  media: {
    objectPosition: 'top',
  },
  action: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  commentContainer: {
    padding: `0 ${theme.spacing(2)}px 0`,
  },
}));

const FeedItem = ({
  post,
  isLiked,
  isHidden,
  onFollow,
  onLoadComments,
}: FeedItemProps) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState<boolean>(false);
  const [showMoreComments, setShowMoreComments] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const onClickComment = () => {
    setExpanded(true);
    if (onLoadComments) {
      onLoadComments();
    }
  };

  const comments = useMemo(() => {
    if (post.comments) {
      const clone = [...post.comments];
      clone.sort((a, b) => (a.published_at < b.published_at ? 1 : -1));
      return showMoreComments ? clone : clone.slice(0, 2);
    }
    return [];
  }, [post.comments, showMoreComments]);

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={(
          <Avatar
            className={classes.avatar}
            src={post?.author?.profilePic?.url}
          >
            {post.author.first_name?.charAt(0).toUpperCase()}
          </Avatar>
        )}
        title={`${post.author.first_name} ${post.author.last_name}`}
        subheader={moment(post.published_at).format('MMMM Do YYYY')}
        action={(
          <>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={(event: any) => {
                setAnchorEl(event.currentTarget);
              }}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem>Save Post</MenuItem>
              <MenuItem>Hide Post</MenuItem>
            </Menu>
          </>
        )}
      />
      {post.media && (
        <CardMedia
          className={classes.media}
          style={{
            aspectRatio:
              post.media.width && post.media.height
                ? `${post.media.width}/${post.media.height}`
                : 'auto',
          }}
          component={
            [
              'video/mp4',
              'application/x-mpegURL',
              'video/3gpp',
              'video/quicktime',
              'video/x-msvideo',
              'video/x-ms-wmv',
            ].includes(post.media.mime || '')
              ? 'video'
              : 'img'
          }
          image={post.media.url}
          alt={post.media.caption}
          controls
        />
      )}
      <CardContent>
        <Typography variant="body1">{post.content}</Typography>
      </CardContent>
      <CardActions disableSpacing className={classes.action}>
        <Box>
          {post.liked?.length !== undefined && post.liked?.length > 0 && (
            <Box display="flex" alignItems="center">
              <FavoritedIcon color="primary" />
              <Typography variant="body2" style={{ marginLeft: 4 }}>
                {post.liked?.length}
              </Typography>
            </Box>
          )}
        </Box>
        <Box display="flex" alignItems="center">
          {!isHidden && (
            <Box display="flex" alignItems="center">
              <IconButton
                aria-label="add to favorites"
                style={{ padding: 4 }}
                onClick={onFollow}
              >
                {/* <Badge badgeContent={post.liked?.length} color="secondary"> */}
                {isLiked ? <FavoritedIcon color="primary" /> : <FavoriteIcon />}
                {/* </Badge> */}
              </IconButton>
              <Typography variant="body2" style={{ marginLeft: 2 }}>
                {isLiked ? 'Liked' : 'Like'}
              </Typography>
            </Box>
          )}
          <Box display="flex" alignItems="center" marginLeft={2}>
            <IconButton
              aria-label="comments"
              style={{ padding: 4 }}
              onClick={onClickComment}
            >
              <CommentIcon />
            </IconButton>
            {post.comments?.length !== undefined
              && post.comments?.length > 0 && (
                <Typography variant="body2" style={{ marginLeft: 2 }}>
                  {post.comments?.length}
                </Typography>
            )}
          </Box>
          <Box marginLeft={2}>
            <IconButton
              aria-label="share"
              style={{ padding: 4 }}
              onClick={() => {}}
            >
              <ShareIcon />
            </IconButton>
          </Box>
        </Box>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent className={classes.commentContainer}>
          <List disablePadding>
            {comments.map((comment) => (
              <FeedCommentItem key={comment.id} comment={comment} />
            ))}
            {post.comments?.length !== undefined
              && post.comments?.length > 2 && (
                <Typography
                  variant="caption"
                  onClick={() => setShowMoreComments((prev) => !prev)}
                >
                  {showMoreComments
                    ? 'Show less comments'
                    : 'Show more comments'}
                </Typography>
            )}
            <NewComment id={post.id} />
          </List>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default FeedItem;
