import {
  POSTS_REQUEST,
  POSTS_SUCCESS,
  POSTS_ERROR,
  POSTS_NEW_REQUEST,
  POSTS_NEW_SUCCESS,
  POSTS_NEW_ERROR,
  POSTS_LIKE_REQUEST,
  POSTS_LIKE_SUCCESS,
  POSTS_LIKE_ERROR,
  POSTS_COMMENT_REQUEST,
  POSTS_COMMENT_SUCCESS,
  POSTS_COMMENT_ERROR,
  POSTS_GET_COMMENT_REQUEST,
  POSTS_GET_COMMENT_SUCCESS,
  POSTS_GET_COMMENT_ERROR,
} from '../actions/posts';
import { APIStatus, Post } from '../../types';

interface PostState {
  fetchStatus: APIStatus;
  requestStatus: APIStatus;
  likeStatus: APIStatus;
  commentStatus: APIStatus;
  data: Post[];
  lastOffset: number;
  error: string | null;
}

const posts = (
  state: PostState = {
    fetchStatus: 'idle',
    requestStatus: 'idle',
    likeStatus: 'idle',
    commentStatus: 'idle',
    data: [],
    lastOffset: 0,
    error: null,
  },
  action: any,
): PostState => {
  const cloneData = [...state.data];
  let index;

  switch (action.type) {
    case POSTS_REQUEST:
      return {
        ...state,
        fetchStatus: 'pending',
      };

    case POSTS_SUCCESS:
      return {
        ...state,
        fetchStatus: 'succeed',
        data: action.offset > 0 ? [...state.data, ...action.posts] : action.posts,
        lastOffset: action.offset,
      };

    case POSTS_ERROR:
      return {
        ...state,
        fetchStatus: 'failed',
        error: action.error,
      };

    case POSTS_NEW_REQUEST:
      return {
        ...state,
        requestStatus: 'pending',
      };

    case POSTS_NEW_SUCCESS:
      return {
        ...state,
        requestStatus: 'succeed',
      };

    case POSTS_NEW_ERROR:
      return {
        ...state,
        requestStatus: 'failed',
      };

    case POSTS_LIKE_REQUEST:
      return {
        ...state,
        likeStatus: 'pending',
      };

    case POSTS_LIKE_SUCCESS:
      index = cloneData.findIndex((post) => post.id === action.id);
      cloneData[index].liked = action.liked;

      return {
        ...state,
        likeStatus: 'succeed',
        data: cloneData,
      };

    case POSTS_LIKE_ERROR:
      return {
        ...state,
        likeStatus: 'failed',
      };

    case POSTS_COMMENT_REQUEST:
      return {
        ...state,
        commentStatus: 'pending',
      };

    case POSTS_COMMENT_SUCCESS:
      index = cloneData.findIndex((post) => post.id === action.id);
      cloneData[index].comments = action.comments;

      return {
        ...state,
        commentStatus: 'succeed',
        data: cloneData,
      };

    case POSTS_COMMENT_ERROR:
      return {
        ...state,
        commentStatus: 'failed',
      };

    case POSTS_GET_COMMENT_REQUEST:
      return {
        ...state,
        commentStatus: 'pending',
      };

    case POSTS_GET_COMMENT_SUCCESS:
      index = cloneData.findIndex((post) => post.id === action.id);
      cloneData[index].comments = action.comments;

      return {
        ...state,
        commentStatus: 'succeed',
      };

    case POSTS_GET_COMMENT_ERROR:
      return {
        ...state,
        commentStatus: 'failed',
      };

    default:
      return state;
  }
};

export default posts;
