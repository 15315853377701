import { Appointment } from '../../types';

// Action constants
export const APPOINTMENTS_REQUEST = 'APPOINTMENTS_REQUEST';
export const APPOINTMENTS_SUCCESS = 'APPOINTMENTS_SUCCESS';
export const APPOINTMENTS_ERROR = 'APPOINTMENTS_ERROR';

// Action builders
export const appointmentsRequest = () => ({
  type: APPOINTMENTS_REQUEST,
});

export const appointmentsSuccess = (appointments: Appointment[]) => ({
  type: APPOINTMENTS_SUCCESS,
  appointments,
});

export const appointmentsError = (error: any) => ({
  type: APPOINTMENTS_ERROR,
  error,
});
