import { CourseInterface } from '../../types';

import styles from './styles.module.scss';

interface CourseHeaderProps {
  course: CourseInterface;
  pageIndex: number;
}

const CourseHeader = ({ course, pageIndex }: CourseHeaderProps) => {
  const progressStyle = {
    transform: `scaleX(${pageIndex / (course.course_pages.length)})`,
  };

  return (
    <header className={styles.courseHeader}>
      <div className={styles.progress}>
        <div className={styles.progressBar} style={progressStyle} />
      </div>
    </header>
  );
};

CourseHeader.displayName = 'CourseHeader';

export default CourseHeader;
