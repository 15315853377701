import { InputContent } from '../../types';

import styles from './styles.module.scss';

interface CourseInputProps {
  content: InputContent;
}

const CourseInput = ({ content }: CourseInputProps) => (
  <div className={styles.wrapper}>
    <p>{content.description}</p>
    {content.paragraph ? (
      <textarea rows={4} className={styles.input} />
    ) : (
      <input type="text" className={styles.input} />
    )}
  </div>
);

CourseInput.displayName = 'CourseInput';

export default CourseInput;
