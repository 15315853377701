import React, { useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import qs from 'query-string';
import {
  Box,
  Typography,
  Paper,
  Button,
  TextField,
  Select,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { RootState } from '../../redux/reducers';
import stateList from '../../utils/states';

import style from '../style';

const useStyles = makeStyles(style);

type ConfirmAddressType = {
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  phone?: string;
};

const services: Record<string, string> = {
  'Maternal Wellness': 'Counselor',
  'Baby Blues/Postpartum Depression': 'Counselor',
  Lactation: 'Lactation',
  'Sleep Training': 'Sleep',
  'Paternal Wellness': 'Counselor',
  'Couples Counseling': 'Counselor',
  Lifestyle: 'Lifestyle',
  'Physical Therapy': 'PT',
  Nutrition: 'Nutrition',
};

interface ConfirmAddressProps {
  loading: boolean;
  onUpdate: (_value: ConfirmAddressType) => void;
}

const ConfirmAddress: React.FC<ConfirmAddressProps> = ({
  loading,
  onUpdate,
}: ConfirmAddressProps) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const [data, setData] = useState<ConfirmAddressType>({
    address: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
  });

  const updateData = (fieldName: string, value: string) => setData((prev) => ({
    ...prev,
    [fieldName]: value,
  }));

  const { insurance_id: insuranceId } = qs.parse(location.search);
  const { data: profile } = useSelector((state: RootState) => state.patient);
  const { data: originServices } = useSelector(
    (rootState: RootState) => rootState.services,
  );

  const onSubmit = (e: any) => {
    e.preventDefault();

    onUpdate(data);

    const currentInstance = originServices.find(
      (ins) => ins.id === Number(insuranceId),
    );
    const service = currentInstance?.name.split('-')[1];
    const insuranceName = `${stateList[data.state || '']}-${service}`;
    const newInstance = originServices.find(
      (ins) => ins.name === insuranceName,
    );

    if (newInstance) {
      if (insuranceId?.toString() !== newInstance?.id?.toString()) {
        history.push(`/schedule?insurance_id=${newInstance.id}`);
      } else {
        history.push('/');
      }
    }
  };

  useEffect(() => {
    if (profile) {
      setData({
        address: profile.address,
        city: profile.city,
        state: profile.state,
        zip: profile.zip,
        phone: profile.phone,
      });
    }
  }, [profile]);

  const availableStates = useMemo(() => Object.keys(stateList).filter((item) => item !== 'Hawaii'), []);

  return (
    <Paper variant="outlined" className={classes.panel}>
      <form onSubmit={onSubmit}>
        <Typography
          className={classes.title}
          variant="h1"
          align="center"
          gutterBottom
        >
          Profile Update
        </Typography>
        <Typography
          className={classes.subtitle}
          variant="h5"
          align="center"
          gutterBottom
        >
          In order to match you with a licensed provider in your area, please
          update your information below. You will receive a text message before
          your scheduled appointment to easily join the waiting room.
        </Typography>
        <Box>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            autoFocus
            id="address"
            label="Address"
            name="address"
            value={data.address}
            onChange={(e: any) => updateData('address', e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="city"
            label="City"
            name="city"
            value={data.city}
            onChange={(e: any) => updateData('city', e.target.value)}
          />
          <Select
            className={classes.stateSelect}
            variant="outlined"
            fullWidth
            required
            id="state"
            value={data.state}
            label="State"
            onChange={(e: any) => updateData('state', e.target.value)}
          >
            <MenuItem value="">Please Select</MenuItem>
            {availableStates.map((item) => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          </Select>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="zip"
            label="Zipcode"
            name="zip"
            value={data.zip}
            onChange={(e: any) => updateData('zip', e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="phone"
            label="Phone Number"
            name="phone"
            value={data.phone}
            onChange={(e: any) => updateData('phone', e.target.value)}
          />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          marginTop={4}
        >
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            disabled={loading}
            type="submit"
          >
            {loading && (
              <CircularProgress className={classes.loadingBtn} size={25} />
            )}
            Next
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            component={Link}
            to="/"
          >
            Cancel
          </Button>
        </Box>
      </form>
    </Paper>
  );
};

export default ConfirmAddress;
