import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import { RootState } from '../../redux/reducers';
import CourseHeader from '../CourseHeader';
import CoursePage from '../CoursePage';
import CourseEnd from '../CourseEnd';
import computeMaxPage from '../../utils/computeMaxPage';

import styles from './styles.module.scss';
import { PageContent } from '../../types';

const useStyles = makeStyles((theme: Theme) => createStyles({
  navButton: {
    padding: '12px 24px',
    marginRight: 12,
    borderRadius: 5,

    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  },
}));

const Course = () => {
  const classes = useStyles();
  const history = useHistory();
  const course = useSelector((state: RootState) => state.course);
  const interactions = useSelector((state: RootState) => state.interactions);
  const [pageIndex, setPageIndex] = useState(0);

  const courses = useMemo(() => {
    const coursePages = course?.course_pages || [];
    const pages: PageContent[] = [];
    coursePages.forEach((page) => {
      pages.push(page);

      const quizPage = page.contents.find(
        (content) => content.__component === 'course-contents.q-a',
      );

      if (quizPage) {
        pages.push({
          ...page,
          answerPage: true,
        });
      }
    });
    return pages;
  }, [course]);

  useEffect(() => {
    const rootElement = document.getElementById('root');
    if (rootElement) {
      rootElement.scrollTo({ top: 0 });
    }
  }, [pageIndex]);

  const maxPage = useMemo(
    () => computeMaxPage(courses, interactions),
    [courses, interactions],
  );

  const hasPreviousPage = pageIndex > 0;
  const hasNextPage = pageIndex <= courses.length - 1;
  const isLastPage = pageIndex === courses.length - 1;

  const onHandleNext = () => {
    if (pageIndex === courses.length - 1) {
      history.push('/');
    } else {
      setPageIndex(pageIndex + 1);
    }
  };

  if (!course) {
    return null;
  }

  return (
    <section className={styles.course}>
      <CourseHeader course={course} pageIndex={pageIndex} />
      <CoursePage page={courses[pageIndex]} />
      <div className={styles.navigation}>
        {hasPreviousPage && (
          <Button
            className={classes.navButton}
            variant="contained"
            color="default"
            onClick={() => setPageIndex(pageIndex - 1)}
          >
            Previous
          </Button>
        )}
        {hasNextPage && maxPage > pageIndex && (
          <Button
            className={classes.navButton}
            variant="contained"
            color="primary"
            onClick={onHandleNext}
          >
            {isLastPage ? 'Finished' : 'Next'}
          </Button>
        )}
      </div>
    </section>
  );
};

Course.displayName = 'Course';

export default Course;
