import {
  CONTENT_TODAY_REQUEST,
  CONTENT_TODAY_SUCCESS,
  CONTENT_TODAY_ERROR,
} from '../actions/today';
import { Content, CourseInterface } from '../../types';

type StateContent = {
  prev: Content | null;
  next: Content | null;
  nextCourse?: CourseInterface | null;
};

interface TodayState {
  loading: boolean;
  content: StateContent;
  error: string | null;
}

const today = (
  state: TodayState = {
    loading: false,
    content: { prev: null, next: null },
    error: null,
  },
  action: any,
): TodayState => {
  switch (action.type) {
    case CONTENT_TODAY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CONTENT_TODAY_SUCCESS:
      return {
        ...state,
        loading: false,
        content: action.content,
      };

    case CONTENT_TODAY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default today;
