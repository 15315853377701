// Action constants
export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';

// Types
export interface StartLoading {
  type: typeof START_LOADING;
}

export interface StopLoading {
  type: typeof STOP_LOADING;
}

// Action builders
export const startLoading = (): StartLoading => ({ type: START_LOADING });

export const stopLoading = (): StopLoading => ({ type: STOP_LOADING });
