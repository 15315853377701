// Action constants
export const REGISTER_AUTH_JWT = 'REGISTER_AUTH_JWT';

// Types
export interface RegisterAuthJWT {
  type: typeof REGISTER_AUTH_JWT;
  token: string;
}

// Action builders
export const registerAuthJWT = (token: string): RegisterAuthJWT => ({
  type: REGISTER_AUTH_JWT,
  token,
});
