const VALID_URL = /^https?:\/\//;

const courseApiPath = (path: string): string => {
  if (path.match(VALID_URL)) {
    return path;
  }
  return `${process.env.REACT_APP_COURSE_API_PATH || ''}${path}`;
};

export default courseApiPath;
