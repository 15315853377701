import React from 'react';
import { Redirect } from 'react-router-dom';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import PaymentView from './Payment';

import style from '../style';
import { RootState } from '../../redux/reducers';

const useStyles = makeStyles(style);

const Membership: React.FC = () => {
  const classes = useStyles();
  const { data: patient, loading: patientLoading } = useSelector(
    (state: RootState) => state.patient,
  );

  if (!patientLoading && patient?.membership !== 'free') {
    return <Redirect to="/" />;
  }

  return (
    <Container className={classes.root} fixed>
      <PaymentView />
    </Container>
  );
};

export default Membership;
