import { FC, useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import {
  Box,
  Typography,
  Menu,
  MenuItem,
  Button,
  Divider,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { RootState } from '../../redux/reducers';
import stateList from '../../utils/states';

const services: Record<string, string> = {
  'Maternal Wellness': 'Counselor',
  'Baby Blues/Postpartum Depression': 'Counselor',
  Lactation: 'Lactation',
  'Sleep Training': 'Sleep',
  'Paternal Wellness': 'Counselor',
  'Couples Counseling': 'Counselor',
  Lifestyle: 'Lifestyle',
  'Physical Therapy': 'PT',
  Nutrition: 'Nutrition',
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  subTitle: {
    fontSize: 18,
    fontWeight: 400,
  },
  selectButton: {
    height: 40,
    lineHeight: 1,
    padding: '6px 12px',
    textTransform: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
  },
  searchButton: {
    margin: '8px auto',
  },
  caption: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 12,
    },
  },
  service: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 0',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
}));

const Schedule: FC = () => {
  const classes = useStyles();
  const [service, setService] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [insuranceId, setInsuranceId] = useState<number | null>(null);
  const { data: originServices } = useSelector(
    (rootState: RootState) => rootState.services,
  );
  const { data: userInfo } = useSelector(
    (rootState: RootState) => rootState.patient,
  );
  const [state, setState] = useState<string | undefined>(userInfo?.state);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (userInfo) {
      setState(userInfo?.state);
    }
  }, [userInfo]);

  useEffect(() => {
    if (service) {
      // const state = userInfo?.state;
      const insuranceName = `${stateList[state || '']}-${services[service]}`;
      const insurance = originServices.find((ins) => ins.name === insuranceName);
      if (insurance) {
        setInsuranceId(insurance.id);
      } else {
        setInsuranceId(null);
      }
    }
  }, [originServices, service, state]);

  return (
    <Box marginTop={3}>
      {/* <Box className={classes.service}>
        <Typography className={classes.caption}>
          Would you like to schedule an appointment with a previous provider?
        </Typography>
        <Switch
          checked={prevProvider}
          onChange={(e: any) => setPrevProvider(e.target.checked)}
          name="previous_provider"
          inputProps={{ 'aria-label': 'With previous provider' }}
        />
      </Box> */}
      <Divider />
      <Box className={classes.service}>
        <Typography className={classes.caption}>
          Which service would you like to schedule?
        </Typography>
        <Box>
          <Button
            className={classes.selectButton}
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            {service || 'Select Service'}
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {Object.keys(services).map((item, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  setService(item);
                  handleClose();
                }}
              >
                {item}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Box>
      <Divider />
      {service && insuranceId && (
        <Box display="flex" justifyContent="center" marginTop={3}>
          <Button
            component={Link}
            variant="contained"
            color="primary"
            className={classes.searchButton}
            to={`/schedule?insurance_id=${insuranceId}`}
          >
            Search Openings
          </Button>
        </Box>
      )}
      {service && !insuranceId && (
        <Box display="flex" justifyContent="center" marginTop={3}>
          Sorry, This service is not available in your area.
        </Box>
      )}
    </Box>
  );
};

export default Schedule;
