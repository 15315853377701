import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Card,
  CardContent,
  CardActions,
  IconButton,
  TextField,
  Box,
  Typography,
  LinearProgress,
  Theme,
  Avatar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PostAdd as SendIcon, Image as ImageIcon } from '@material-ui/icons';
import { red, blue } from '@material-ui/core/colors';
import { RootState } from '../../redux/reducers';
import { uploadImage } from '../../services/community';

interface NewFeedProps {
  focus: boolean;
  onFocused: () => void;
  onSubmit: (request: any) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 8px',
    borderRadius: theme.spacing(2),
  },
  submit: {
    color: blue[600],
  },
  mainInput: {
    borderRadius: theme.spacing(3),
  },
  media: {
    color: red[500],
  },
  input: {
    width: 0,
    visibility: 'hidden',
  },
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    marginRight: theme.spacing(2),
  },
}));

const NewFeed = ({ focus, onFocused, onSubmit }: NewFeedProps) => {
  const [file, setFile] = useState<File | null>(null);
  const [content, setContent] = useState<string>('');
  const [uploading, setUploading] = useState<boolean>(false);
  const myProfile = useSelector((state: RootState) => state.patient.data);
  const feed = useSelector((state: RootState) => state.posts);
  const inputRef = useRef<any>(null);

  const classes = useStyles();

  const onSend = async () => {
    if (content) {
      try {
        let media;
        if (file) {
          setUploading(true);
          const uploaded = await uploadImage(file);
          if (uploaded.length > 0) {
            media = uploaded[0].id;
          }
        }
        onSubmit({
          media,
          content,
        });
        setUploading(false);
      } catch (error) {
        console.log(error);
        setUploading(false);
      }
    }
  };

  useEffect(() => {
    if (feed.requestStatus === 'succeed') {
      setContent('');
      setFile(null);
      setUploading(false);
    }
  }, [feed.requestStatus]);

  useEffect(() => {
    if (focus && inputRef.current) {
      inputRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      inputRef.current.focus();
      onFocused();
    }
  }, [focus, onFocused]);

  const isLoading = feed.requestStatus === 'pending' || uploading;

  return (
    <Box marginBottom={2}>
      <Card className={classes.root}>
        <CardContent style={{ paddingBottom: 0 }}>
          {isLoading && <LinearProgress />}
          <Box display="flex" alignItems="center">
            <Avatar
              className={classes.avatar}
              alt="Avatar"
              src={myProfile?.profilePic?.url}
            >
              {myProfile?.first_name?.charAt(0)}
            </Avatar>
            <TextField
              autoFocus={focus}
              ref={inputRef}
              fullWidth
              id="filled-multiline-static"
              label="What's on your mind, neighbor?"
              rows={4}
              variant="outlined"
              value={content}
              disabled={isLoading}
              InputProps={{
                className: classes.mainInput,
              }}
              onChange={(e) => setContent(e.target.value)}
            />
          </Box>
          {file && <Typography>{file.name}</Typography>}
        </CardContent>
        <CardActions disableSpacing>
          <IconButton
            aria-label="send"
            className={classes.submit}
            disabled={isLoading}
            onClick={onSend}
          >
            <SendIcon />
          </IconButton>
          <input
            accept="image/*,video/*"
            className={classes.input}
            id="icon-button-file"
            type="file"
            onChange={(e) => e.target.files && setFile(e.target.files[0])}
          />
          <label htmlFor="icon-button-file">
            <IconButton
              aria-label="image"
              className={classes.media}
              component="span"
              disabled={isLoading}
            >
              <ImageIcon />
            </IconButton>
          </label>
        </CardActions>
      </Card>
    </Box>
  );
};

export default NewFeed;
