import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Typography,
  Paper,
  Button,
  TextField,
  Select,
  FormControl,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import moment from 'moment';
import {
  applyPromocode,
  makePayment,
  bookAppointment,
} from '../../services/appointment';
import style from '../style';
import { RootState } from '../../redux/reducers';
import loadPatient from '../../redux/thunks/loadPatient';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY || '');

const useStyles = makeStyles(style);

interface PaymentProps {
  hasMembership?: boolean;
  time: string;
  insuranceId: number;
}

interface CheckoutFormProps {
  hasMembership?: boolean;
  time: string;
  insuranceId: number;
  onConfirm: () => void;
}

const CheckoutForm: React.FC<CheckoutFormProps> = ({
  time,
  insuranceId,
  onConfirm,
}) => {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const profile = useSelector((state: RootState) => state.patient.data);
  const [promo, setPromo] = useState('');
  const [promoError, setPromoError] = useState<boolean>(false);
  const [promoSuccess, setPromoSuccess] = useState<boolean>(false);
  const [payError, setPayError] = useState<boolean>(false);
  const [cardComplete, setCardComplete] = useState<boolean>(false);
  const [paying, setPaying] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(profile?.membership === 'free' ? 50 : 35);

  const onCardChange = (e: any) => {
    setCardComplete(!e.error && e.complete);
  };

  useEffect(() => {
    if (profile?.membership === 'membership') {
      if (!profile?.last_free_session_date || !moment().isSame(profile.last_free_session_date, 'month')) {
        if (moment().add(2, 'month').isSameOrAfter(time)) {
          setAmount(0);
        }
      }
    }
  }, [time, profile]);

  const onPay = async () => {
    setPayError(false);
    setPaying(true);
    try {
      if (amount === 0) {
        await bookAppointment(insuranceId, time, false);
        onConfirm();
      } else if (stripe && elements) {
        const element = elements.getElement(CardElement);
        const response = await makePayment(amount * 100);

        if (response.client_secret && element) {
          const result = await stripe.confirmCardPayment(
            response.client_secret,
            {
              payment_method: {
                // type: 'card',
                card: element,
              },
            },
          );
          if (result.paymentIntent?.status === 'succeeded') {
            await bookAppointment(insuranceId, time, true);
            onConfirm();
          } else {
            console.log(result.error?.message);
            setPayError(true);
          }
        } else {
          setPayError(true);
        }
      } else {
        setPayError(true);
      }
    } catch (error) {
      setPayError(true);
    } finally {
      setPaying(false);
    }
  };

  const onApplyPromo = async () => {
    setPromoError(false);
    setPromoSuccess(false);
    try {
      const response = await applyPromocode(promo);

      if (response.success && response.amount) {
        setAmount(response.amount / 100);
        setPromoSuccess(true);
      } else {
        setPromoError(true);
      }
    } catch (error) {
      setPromoError(true);
    }
  };

  return (
    <Box marginTop={4}>
      <FormControl variant="outlined" className={classes.formItem}>
        <Select native>
          <option value={10}>{`1-on-1 Session - ${amount > 0 ? `$${amount}` : 'Free'}`}</option>
        </Select>
      </FormControl>
      {amount !== 0 && (
        <Box display="flex" alignItems="center" marginTop={2}>
          <TextField
            className={classes.formItem}
            value={promo}
            id="outlined-basic"
            label="Promo Code"
            variant="outlined"
            disabled={promoSuccess}
            onChange={(e) => setPromo(e.target.value)}
          />
          <Button
            className={classes.applyButton}
            variant="contained"
            color="primary"
            disabled={!promo || promoSuccess}
            onClick={onApplyPromo}
          >
            Apply
          </Button>
        </Box>
      )}
      {amount !== 0 && (
        <Box marginTop={2}>
          <CardElement
            className={classes.stripeContainer}
            onChange={onCardChange}
            options={{
              style: {
                base: {
                  padding: '18.5px 14px',
                  fontSize: '16px',
                },
              },
            }}
          />
        </Box>
      )}
      <Box marginTop={2} marginBottom={2}>
        <Button
          disabled={paying || (Boolean(amount) && (!stripe || !cardComplete))}
          variant="contained"
          color="primary"
          onClick={onPay}
        >
          {paying && (
            <CircularProgress className={classes.loadingBtn} size={25} />
          )}
          {`Pay $${amount.toFixed(2)}`}
        </Button>
      </Box>
      {promoError && <Alert severity="error">Promo code not valid.</Alert>}
      {payError && (
        <Alert severity="error">There was an issue making payment.</Alert>
      )}
    </Box>
  );
};

const Payment: React.FC<PaymentProps> = ({
  insuranceId,
  time,
  hasMembership,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const onConfirm = () => {
    dispatch(loadPatient());
    history.push(`/confirmed?time=${time}`);
  };

  return (
    <Paper variant="outlined" className={classes.panel}>
      <Typography
        className={classes.title}
        variant="h1"
        align="center"
        gutterBottom
      >
        Payment
      </Typography>
      <Typography
        className={classes.subtitle}
        variant="h5"
        align="center"
        gutterBottom
      >
        Pay securely with a credit card and get started with your maternal
        wellness.
      </Typography>
      <Elements stripe={stripePromise}>
        <CheckoutForm
          hasMembership={hasMembership}
          insuranceId={insuranceId}
          time={time}
          onConfirm={onConfirm}
        />
      </Elements>
    </Paper>
  );
};

export default Payment;
