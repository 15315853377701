import { CourseInteractions, PageContent } from '../types';
import checkFunctions from '../checkFunctions';

const computeMaxPage = (
  courses: PageContent[],
  interactions: CourseInteractions,
) => {
  if (!courses) {
    return 0;
  }

  for (let i = 0; i < courses.length; i += 1) {
    for (let j = 0; j < checkFunctions.length; j += 1) {
      if (!checkFunctions[j](courses[i], interactions)) {
        return i;
      }
    }
  }
  return courses.length;
};

export default computeMaxPage;
