// Action constants
export const OPENINGS_REQUEST = 'OPENINGS_REQUEST';
export const OPENINGS_SUCCESS = 'OPENINGS_SUCCESS';
export const OPENINGS_ERROR = 'OPENINGS_ERROR';
export const OPENINGS_RESET = 'OPENINGS_RESET';

// Action builders
export const openingsRequest = () => ({
  type: OPENINGS_REQUEST,
});

export const openingsSuccess = (openings: string[]) => ({
  type: OPENINGS_SUCCESS,
  openings,
});

export const openingsError = (error: any) => ({
  type: OPENINGS_ERROR,
  error,
});

export const openingsReset = () => ({
  type: OPENINGS_RESET,
});
