import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Box, CircularProgress } from '@material-ui/core';
import { RootState } from '../../redux/reducers';
import loadCourse from '../../redux/thunks/loadCourse';
import Course from '../../components/Course';

interface CourseParams {
  courseSlug: string;
}

const CourseView = () => {
  const dispatch = useDispatch();
  const course = useSelector((state: RootState) => state.course);
  const loading = useSelector((state: RootState) => state.loading);
  const history = useHistory();
  const { courseSlug } = useParams<CourseParams>();

  useEffect(() => {
    const innerEffect = async () => {
      if (!course || course.slug !== courseSlug) {
        const result = await dispatch(loadCourse(courseSlug));
        if (!result) {
          history.replace('/');
        }
      }
    };
    innerEffect();
  }, [dispatch, history, course, courseSlug]);

  if (!course || loading > 0) {
    return <CircularProgress />;
  }

  return (
    <Container fixed>
      <Box paddingTop={1}>
        <Course />
      </Box>
    </Container>
  );
};

CourseView.displayName = 'Course';

export default CourseView;
