import {
  StoreCourse,
  ClearCourse,
  STORE_COURSE,
  CLEAR_COURSE,
} from '../actions/courses';
import { Logout, LOGOUT } from '../actions/user';

import { CourseInterface } from '../../types';

type CourseState = CourseInterface | null;

const course = (
  state: CourseState = null,
  action: StoreCourse | ClearCourse | Logout,
): CourseState => {
  switch (action.type) {
    case STORE_COURSE:
      return action.course;

    case CLEAR_COURSE:
    case LOGOUT:
      return null;

    default:
      return state;
  }
};

export default course;
