import { Post, Comment, Patient } from '../../types';

// Action constants
export const POSTS_REQUEST = 'POSTS_REQUEST';
export const POSTS_SUCCESS = 'POSTS_SUCCESS';
export const POSTS_ERROR = 'POSTS_ERROR';

export const POSTS_NEW_REQUEST = 'POSTS_NEW_REQUEST';
export const POSTS_NEW_SUCCESS = 'POSTS_NEW_SUCCESS';
export const POSTS_NEW_ERROR = 'POSTS_NEW_ERROR';

export const POSTS_LIKE_REQUEST = 'POSTS_LIKE_REQUEST';
export const POSTS_LIKE_SUCCESS = 'POSTS_LIKE_SUCCESS';
export const POSTS_LIKE_ERROR = 'POSTS_LIKE_ERROR';

export const POSTS_COMMENT_REQUEST = 'POSTS_COMMENT_REQUEST';
export const POSTS_COMMENT_SUCCESS = 'POSTS_COMMENT_SUCCESS';
export const POSTS_COMMENT_ERROR = 'POSTS_COMMENT_ERROR';

export const POSTS_GET_COMMENT_REQUEST = 'POSTS_GET_COMMENT_REQUEST';
export const POSTS_GET_COMMENT_SUCCESS = 'POSTS_GET_COMMENT_SUCCESS';
export const POSTS_GET_COMMENT_ERROR = 'POSTS_GET_COMMENT_ERROR';

// Action builders
export const postsRequest = () => ({
  type: POSTS_REQUEST,
});

export const postsSuccess = (posts: Post[], offset: number = 0) => ({
  type: POSTS_SUCCESS,
  posts,
  offset,
});

export const postsError = (error: any) => ({
  type: POSTS_ERROR,
  error,
});

export const newPostRequest = () => ({
  type: POSTS_NEW_REQUEST,
});

export const newPostSuccess = (post: Post) => ({
  type: POSTS_NEW_SUCCESS,
  post,
});

export const newPostError = (error: any) => ({
  type: POSTS_NEW_ERROR,
  error,
});

export const likePostRequest = () => ({
  type: POSTS_LIKE_REQUEST,
});

export const likePostSuccess = (id: string, liked: Patient[]) => ({
  type: POSTS_LIKE_SUCCESS,
  id,
  liked,
});

export const likePostError = (error: any) => ({
  type: POSTS_LIKE_ERROR,
  error,
});

export const commentPostRequest = () => ({
  type: POSTS_COMMENT_REQUEST,
});

export const commentPostSuccess = (id: string, comments: Comment[]) => ({
  type: POSTS_COMMENT_SUCCESS,
  id,
  comments,
});

export const commentPostError = (error: any) => ({
  type: POSTS_COMMENT_ERROR,
  error,
});

export const getCommentsRequest = () => ({
  type: POSTS_GET_COMMENT_REQUEST,
});

export const getCommentsSuccess = (id: string, comments: Comment[]) => ({
  type: POSTS_GET_COMMENT_SUCCESS,
  id,
  comments,
});

export const getCommentsError = (error: any) => ({
  type: POSTS_GET_COMMENT_ERROR,
  error,
});
