import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { VideoContent } from '../../types';
import courseApiPath from '../../services/courseApiPath';

import styles from './styles.module.scss';
import { interactWithCourse } from '../../redux/actions/courses';

interface CourseVideoProps {
  content: VideoContent;
}

const CourseVideo = ({ content }: CourseVideoProps) => {
  const dispatch = useDispatch();
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) {
      return;
    }

    const onVideoEnded = () => {
      dispatch(
        interactWithCourse('video', content.name, {
          video: content,
          finished: true,
        }),
      );
    };

    video.addEventListener('ended', onVideoEnded);

    return () => video.removeEventListener('ended', onVideoEnded);
  }, [content, dispatch]);

  if (!content.video) return null;

  return (
    <video
      className={styles.video}
      controls
      ref={videoRef}
      poster={content.video_preview ? content.video_preview.url : undefined}
      src={courseApiPath(content.video.url)}
    />
  );
};

CourseVideo.displayName = 'CourseVideo';

export default CourseVideo;
