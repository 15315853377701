import React, { useState } from 'react';
import qs from 'query-string';
import { useLocation, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  Paper,
  Grid,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { resetPassword } from '../../services/auth';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage:
      'url(https://maternalwell.com/wp-content/uploads/2019/06/banner-2-e1612646602556.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ResetPassword: React.FC = () => {
  const location = useLocation();
  const classes = useStyles();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const { code } = qs.parse(location.search);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    setError(false);
    try {
      await resetPassword(String(code), password, confirmPassword);
      setLoading(false);
      setSuccess(true);
    } catch (err: any) {
      setLoading(false);
      setError(true);
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={4} md={8} className={classes.image} />
      <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          {success ? (
            <>
              <Alert elevation={6} variant="filled" severity="success">
                Your password has been set. Login
                {' '}
                <Link to="/login">here</Link>
                {' '}
                to login.
              </Alert>
            </>
          ) : (
            <form className={classes.form} onSubmit={onSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                value={password}
                onChange={(e: any) => setPassword(e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password_confirm"
                label="Confirm Password"
                type="password"
                id="password"
                value={confirmPassword}
                onChange={(e: any) => setConfirmPassword(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                className={classes.submit}
                disabled={loading || password !== confirmPassword}
              >
                Reset Password
              </Button>
            </form>
          )}
          {loading && <CircularProgress />}
          {error && (
            <Alert elevation={6} variant="filled" severity="error">
              There was an issue sending the request!
            </Alert>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default ResetPassword;
