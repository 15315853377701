import {
  LOGOUT, AUTH_REQUEST, AUTH_SUCCESS, AUTH_ERROR,
} from '../actions/user';
import { Patient } from '../../types';

interface PatientState {
  loading: boolean;
  data: Patient | null;
  error: boolean;
}

interface ActionType {
  type: typeof AUTH_REQUEST | typeof AUTH_SUCCESS | typeof AUTH_ERROR | typeof LOGOUT;
  payload?: any;
}

const patient = (
  state: PatientState = { loading: false, data: null, error: false },
  action: ActionType,
): PatientState => {
  switch (action.type) {
    case LOGOUT:
      return {
        ...state,
        data: null,
      };
    case AUTH_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
      };
    case AUTH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export default patient;
