import { AppThunk } from '../../types';
import { startLoading, stopLoading } from '../actions/loading';
import { clearCourse, storeCourse } from '../actions/courses';
import * as coursesApi from '../../services/courses';

const loadCourse = (courseSlug: string): AppThunk => async (dispatch) => {
  dispatch(startLoading());
  const course = await coursesApi.loadCourse(courseSlug);
  if (!course) {
    dispatch(clearCourse());
  } else {
    dispatch(storeCourse(course));
  }
  dispatch(stopLoading());

  return course;
};

export default loadCourse;
