import {
  OPENINGS_REQUEST,
  OPENINGS_SUCCESS,
  OPENINGS_ERROR,
  OPENINGS_RESET,
} from '../actions/openings';

interface OpeningsState {
  loading: boolean;
  data: string[];
  error: string | null;
}

const openings = (
  state: OpeningsState = { loading: false, data: [], error: null },
  action: any,
): OpeningsState => {
  switch (action.type) {
    case OPENINGS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case OPENINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.openings,
      };

    case OPENINGS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case OPENINGS_RESET:
      return {
        ...state,
        data: [],
        error: null,
      };

    default:
      return state;
  }
};

export default openings;
