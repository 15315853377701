import { CourseInteractions } from '../../types';
import {
  ClearCourse, CLEAR_COURSE, InteractWithCourse, INTERACT_WITH_COURSE,
} from '../actions/courses';
import { LOGOUT, Logout } from '../actions/user';

const interactions = (
  state: CourseInteractions = { quiz: {}, video: {} },
  action: ClearCourse | InteractWithCourse | Logout,
): CourseInteractions => {
  let newContent;

  switch (action.type) {
    case CLEAR_COURSE:
    case LOGOUT:
      return { quiz: {}, video: {} };

    case INTERACT_WITH_COURSE:
      newContent = { ...state[action.interactionType] };
      if (action.value) {
        newContent[action.key] = action.value;
      } else {
        delete newContent[action.key];
      }

      return { ...state, [action.interactionType]: newContent };

    default:
      return state;
  }
};

export default interactions;
