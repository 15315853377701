import axios from 'axios';
import { strapiPath } from './authApiPath';
import { Appointment, Service, Opening } from '../types';

export const loadAppointments = async (): Promise<Appointment[]> => {
  const response = await axios.get(strapiPath('/appointments'));
  const responseData = await response.data;

  return responseData;
};

export const loadServices = async (): Promise<Service[]> => {
  const response = await axios.get(strapiPath('/appointments/services'));
  const responseData = await response.data;

  return responseData;
};

export const loadOpenings = async (
  from: string,
  to: string,
  insuranceId: string,
): Promise<Opening> => {
  const response = await axios.get(
    strapiPath(
      `/appointments/opening?insurance_id=${insuranceId}&from=${from}&to=${to}`,
    ),
  );
  const responseData = await response.data;

  return responseData;
};

export const bookAppointment = async (
  insuranceId: number,
  time: string,
  charged?: boolean,
): Promise<void> => {
  const response = await axios.post(strapiPath('/appointments'), {
    insurance_id: insuranceId,
    time,
    charged,
  });
  const responseData = await response.data;

  return responseData;
};

export const applyPromocode = async (
  code: string,
): Promise<{ success: boolean; amount?: number; code?: string }> => {
  const response = await axios.post(strapiPath('/appointments/promotion'), {
    code,
  });

  return response.data;
};

export const makePayment = async (
  amount: number,
): Promise<{ client_secret?: string }> => {
  const response = await axios.post(strapiPath('/appointments/payment'), {
    amount,
  });

  return response.data;
};

export const updateProfile = async (request: any): Promise<void> => {
  const response = await axios.put(strapiPath('/appointments/profile'), request);

  return response.data;
};
